import * as React from "react";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import { useMutation } from "react-apollo";
import { updateAdminUserPassword } from "../../queries/userQueries";
import { toast } from "react-toastify";
import {
  UpdateAdminUserPasswordMutation,
  UpdateAdminUserPasswordMutationVariables,
} from "../../../types";

interface AdminChangePasswordModalProps {
  adminUserId: string;
  adminUserName: string;
  open: boolean;
  close: () => void;
  onUpdated: () => void;
}

export const AdminChangePasswordModal = (
  props: AdminChangePasswordModalProps
) => {
  // Local state to keep track of input changes
  const [password, setPassword] = React.useState<string>();
  const [password2, setPassword2] = React.useState<string>();

  /* Mutations */

  // Mutaton: Create Admin
  const [updatePassword, updatePasswordData] = useMutation<
    UpdateAdminUserPasswordMutation,
    UpdateAdminUserPasswordMutationVariables
  >(updateAdminUserPassword, {
    onCompleted() {
      props.close();
      toast.success("Aðgerð heppnaðist: Breytt um lykilorð hjá stjórnanda.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  const isValid = () => {
    return password && password !== "" && password2 && password2 === password;
  };

  return (
    <Modal open={props.open}>
      <Loader active={updatePasswordData.loading} />
      <Modal.Header>Breyta lykilorði fyrir {props.adminUserName}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Lykilorð</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Lykilorð"
            />
          </Form.Field>
          <Form.Field>
            <label>Lykilorð (aftur)</label>
            <input
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder="Lykilorð (aftur)"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            // Create the admin user
            updatePassword({
              variables: {
                id: props.adminUserId,
                password: password || "",
                password_again: password2 || "",
              },
            }).then(() => {
              // Reset inputs.
              setPassword("");
              setPassword2("");
              // Close when succesfully updated.
              props.close();
              // Trigger update event
              props.onUpdated();
            });
          }}
          disabled={!isValid()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Breyta lykilorði"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AdminChangePasswordModal;
