import * as React from "react";
import {
  Modal,
  Button,
  Icon,
  Select,
  Popup,
  Dropdown,
  Container,
  Segment,
} from "semantic-ui-react";
import { useMutation, useLazyQuery } from "react-apollo";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import {
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
  AssignDocumentToPropertyMutation,
  AssignDocumentToPropertyMutationVariables,
  UploadDocumentsV2Mutation,
  UploadDocumentsV2MutationVariables,
  DocumentAccessType,
} from "../../../types";
import {
  updateDocument,
  assignDocumentToProperty,
  uploadDocumentsV2,
} from "../../queries/documentQueries";
import styled from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import is from "date-fns/locale/is";
import { getKnownDocumentTypesOptions } from "../../utils/document";
import { getFontAwesomeIconFromMIME } from "../../utils/fileicons";
import { getProperties } from "../../queries/propertyQueries";
import { notEmpty } from "../../utils/array";

/* hack to get react-datepicker-wrapper styled properly inside a list content block.  */
const StyledDatePickerWrapper = styled.div`
  display: inline;
  input {
    border: 1px solid #d4d4d5;
    padding: 8px;
    border-radius: 4px;
  }
`;

interface DocumentUploadModalProps {
  open: boolean;
  close: () => void;
  onUpdated: () => void;
}

registerLocale("is", is);

const UploadContainer = styled.div`
  height: 200px;
  background-color: #f8f8f8;
  text-align: center;
  padding-top: 60px;
`;

const ItemRow = styled.div`
  display: flex;
  margin: 8px;
  align-items: center;
  .comboxbox {
    margin-right: 4px;
  }
`;

const ItemLabel = styled.div`
  margin-right: 8px;
`;

export const DocumentUploadModal = (props: DocumentUploadModalProps) => {
  const [recentlyUploadedDocuments, setRecentlyUploadedDocuments] = useState<
    {
      id: string;
      name: string;
      mimetype: string;
      type: string;
      description: string;
      displayDate: string;
    }[]
  >([]);

  const [assignmentChoices, setAssignmentChoices] = useState<
    {
      id: string;
      propertyId: string;
      accessType?: DocumentAccessType;
    }[]
  >([]);

  /* Queries */

  // Query: Get Properties
  const [getPropertiesQuery, propertiesResult] = useLazyQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    variables: {
      limit: 1000,
      page: 0,
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  /* Mutations */

  // Mutaton: Upload Documents
  const [uploadDocumentsMutation] = useMutation<
    UploadDocumentsV2Mutation,
    UploadDocumentsV2MutationVariables
  >(uploadDocumentsV2, {
    onCompleted(data) {
      setRecentlyUploadedDocuments(
        data.uploadDocumentsV2?.map((doc) => {
          return {
            id: doc.id,
            name: doc.name,
            mimetype: doc.mimetype,
            type: doc.type,
            description: doc.description,
            displayDate: doc.displayDate,
          };
        }) || []
      );
      setAssignmentChoices([]);
      toast.success("Aðgerð heppnaðist: Upphalaði skjali..");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Update Document By Id.
  const [updateDocumentMutation] = useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(updateDocument, {
    onCompleted() {
      toast.success("Aðgerð heppnaðist: Skjal uppfært");
    },
    onError(...error) {
      toast.error("Aðgerð heppnaðist ekki: " + error.map((x) => x.message)[0]);
    },
  });

  const [assignDocumentToPropertyMutation] = useMutation<
    AssignDocumentToPropertyMutation,
    AssignDocumentToPropertyMutationVariables
  >(assignDocumentToProperty, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Leyfði húsfélagi að skoða skjal.");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validity = e.target.validity;
    const files = e.target.files ? Array.from(e.target.files) : [];
    validity.valid &&
      uploadDocumentsMutation({
        variables: { files: files, type: "", description: "" },
      }).then(() => {
        props.onUpdated();
      });
  };

  // Effects
  React.useEffect(() => {
    if (props.open) getPropertiesQuery();
  }, [getPropertiesQuery, props.open]);

  return (
    <Modal open={props.open}>
      <Modal.Header>Hlaða upp skjölum</Modal.Header>
      <Modal.Content>
        <Container>
          {recentlyUploadedDocuments.length > 0 && (
            <>
              <h3>Upphlaðin skjöl:</h3>
              <Segment>
                {recentlyUploadedDocuments.map((file, index) => (
                  <>
                    <ItemRow>
                      <Popup
                        content={file.mimetype}
                        key={index + "mime"}
                        header={"Skráartýpa"}
                        trigger={
                          <Icon
                            name={getFontAwesomeIconFromMIME(file.mimetype)}
                            size="big"
                          />
                        }
                      />
                      {file.name}
                    </ItemRow>
                    <ItemRow>
                      <ItemLabel>Skjalaflokkur:</ItemLabel>
                      <Select
                        placeholder="Skjalaflokkur"
                        options={getKnownDocumentTypesOptions()}
                        onChange={(_, { value }) => {
                          setRecentlyUploadedDocuments(
                            recentlyUploadedDocuments.map((item, i) =>
                              index === i
                                ? { ...item, type: (value as string) || "" }
                                : item
                            )
                          );
                          updateDocumentMutation({
                            variables: {
                              id: file.id || "",
                              document: {
                                name: file.name || "",
                                description: file.description || "",
                                type: value ? value.toString() : "",
                                displayDate:
                                  file.displayDate || new Date().toISOString(),
                              },
                            },
                          });
                        }}
                      />
                    </ItemRow>
                    <ItemRow>
                      <ItemLabel>Heimild húsfélags</ItemLabel>
                      <Dropdown
                        placeholder="Heimild húsfélags"
                        selection
                        search
                        options={(propertiesResult?.data?.properties.data || [])
                          .filter(notEmpty)
                          .map((property) => {
                            return {
                              key: property.id,
                              text: property.address,
                              value: property.id,
                            };
                          })}
                        onChange={(_, data) => {
                          data.value &&
                          assignmentChoices.find((opt) => opt.id === file.id)
                            ? setAssignmentChoices(
                                assignmentChoices.map((choice) =>
                                  file.id === choice.id
                                    ? {
                                        ...choice,
                                        propertyId:
                                          data.value?.toString() || "",
                                      }
                                    : choice
                                )
                              )
                            : data.value &&
                              setAssignmentChoices([
                                ...assignmentChoices,
                                {
                                  id: file.id,
                                  propertyId: data.value.toString(),
                                  accessType: undefined,
                                },
                              ]);
                        }}
                      />
                      <ItemLabel>Aðgangsstýring</ItemLabel>
                      <Dropdown
                        placeholder="Aðgangur"
                        selection
                        search
                        options={[
                          {
                            value: DocumentAccessType.Basic,
                            text: "Allir í húsfélaginu",
                          },
                          {
                            value: DocumentAccessType.Full,
                            text: "Eingöngu aðilar með fullan aðgang",
                          },
                        ]}
                        onChange={(_, data) => {
                          data.value &&
                          assignmentChoices.find((opt) => opt.id === file.id)
                            ? setAssignmentChoices(
                                assignmentChoices.map((choice) =>
                                  file.id === choice.id
                                    ? {
                                        ...choice,
                                        accessType: data.value as DocumentAccessType,
                                      }
                                    : choice
                                )
                              )
                            : data.value &&
                              setAssignmentChoices([
                                ...assignmentChoices,
                                {
                                  propertyId: "",
                                  id: file.id,
                                  accessType: data.value as DocumentAccessType,
                                },
                              ]);
                        }}
                      />{" "}
                      <Popup
                        content="Gefa völdu húsfélagi aðgang að skjalinu"
                        trigger={
                          <Button
                            icon="save"
                            primary
                            disabled={
                              !assignmentChoices.find(
                                (p) =>
                                  p.id === file.id &&
                                  p.propertyId &&
                                  p.accessType
                              )
                            }
                            onClick={() => {
                              const choosenProperty = assignmentChoices.find(
                                (c) => c.id === file.id
                              );
                              choosenProperty &&
                                assignDocumentToPropertyMutation({
                                  variables: {
                                    documentId: file.id,
                                    propertyId: choosenProperty.propertyId,
                                    accessType:
                                      choosenProperty.accessType ||
                                      DocumentAccessType.Basic,
                                  },
                                }).then(() => {
                                  // Clear all last choosen properties
                                  setAssignmentChoices(
                                    assignmentChoices.filter(
                                      (choice) =>
                                        choice.id !== choosenProperty.id
                                    )
                                  );
                                });
                            }}
                          />
                        }
                      />
                    </ItemRow>
                    <ItemRow>
                      <ItemLabel>Dagsetning skjals</ItemLabel>
                      <StyledDatePickerWrapper>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          locale="is"
                          selected={
                            new Date(
                              recentlyUploadedDocuments[index].displayDate
                            )
                          }
                          onChange={(date) => {
                            // Update the local state
                            const newDisplayDate =
                              date?.toISOString() || new Date().toISOString();
                            setRecentlyUploadedDocuments(
                              recentlyUploadedDocuments.map((item, i) =>
                                index === i
                                  ? {
                                      ...item,
                                      displayDate: newDisplayDate,
                                    }
                                  : item
                              )
                            );
                            // Update the document in the system
                            updateDocumentMutation({
                              variables: {
                                id: file.id || "",
                                document: {
                                  name: file.name || "",
                                  description: file.description || "",
                                  type: file.type,
                                  displayDate: newDisplayDate,
                                },
                              },
                            });
                          }}
                        />
                      </StyledDatePickerWrapper>
                    </ItemRow>
                  </>
                ))}
              </Segment>
            </>
          )}
          <UploadContainer {...getRootProps()}>
            <input {...getInputProps()} onChange={onChange} />
            {isDragActive ? (
              <p>Dragðu skrárnar hingað...</p>
            ) : (
              <p>Dragðu skrár hingað, eða veldur skrár..</p>
            )}
            <Icon size="big" name="upload" />
          </UploadContainer>
        </Container>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.onUpdated();
            setRecentlyUploadedDocuments([]);
            setAssignmentChoices([]);
            props.close();
          }}
        >
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DocumentUploadModal;
