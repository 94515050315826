import * as React from "react";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import { useMutation } from "react-apollo";
import { createAdminUser } from "../../queries/userQueries";
import { toast } from "react-toastify";
import {
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables,
  AdminRole,
} from "../../../types";
import { toEnumIgnoreCase } from "../../utils/conversion";

interface AdminCreateModalProps {
  open: boolean;
  close: () => void;
  onUpdated: () => void;
}

export const AdminCreateModal = (props: AdminCreateModalProps) => {
  // Local state to keep track of input changes
  const [userName, setUserName] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [role, setRole] = React.useState<AdminRole | null>();
  const [password, setPassword] = React.useState<string>();
  const [password2, setPassword2] = React.useState<string>();

  /* Mutations */

  // Mutaton: Create Admin
  const [createAdminMutation, createAdminData] = useMutation<
    CreateAdminUserMutation,
    CreateAdminUserMutationVariables
  >(createAdminUser, {
    onCompleted(data) {
      props.close();
      toast.success("Aðgerð heppnaðist: Stjórnandi búinn til.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  const isValid = () => {
    return (
      name &&
      name !== "" &&
      userName &&
      userName !== "" &&
      email &&
      email !== "" &&
      role &&
      role !== null &&
      password &&
      password !== "" &&
      password2 &&
      password2 === password
    );
  };

  return (
    <Modal open={props.open}>
      <Loader active={createAdminData.loading} />
      <Modal.Header>Búa til nýjan stjórnanda</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Nafn</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nafn"
            />
          </Form.Field>
          <Form.Field>
            <label>Notendanafn</label>
            <input
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="Notendanafn"
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              fluid
              label="Réttindi"
              value={role || ""}
              options={[
                {
                  key: "SUPERUSER",
                  text: "Ofurnotandi",
                  name: "Ofurnotandi",
                  value: "SUPERUSER",
                },
                {
                  key: "UPLOADER",
                  text: "Skjalastjórnandi",
                  name: "Skjalastjórnandi",
                  value: "UPLOADER",
                },
              ]}
              onChange={(_, { value }) =>
                setRole(
                  toEnumIgnoreCase(AdminRole, (value && value.toString()) || "")
                )
              }
              placeholder="Réttindi"
            />
          </Form.Field>
          <Form.Field>
            <label>Póstfang</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Póstfang"
            />
          </Form.Field>
          <Form.Field>
            <label>Lykilorð</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Lykilorð"
            />
          </Form.Field>
          <Form.Field>
            <label>Lykilorð (aftur)</label>
            <input
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder="Lykilorð (aftur)"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            // Create the admin user
            createAdminMutation({
              variables: {
                user: {
                  name: name || "",
                  userName: userName || "",
                  email: email || "",
                  role: AdminRole.Superuser,
                  password: password || "",
                  password_again: password2 || "",
                },
              },
            }).then(() => {
              // Close when succesfully updated.
              props.close();
              // Trigger update event
              props.onUpdated();
            });
          }}
          disabled={!isValid()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Búa til stjórnanda"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AdminCreateModal;
