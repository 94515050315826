import React from "react";
import ReactDOM from "react-dom";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import { configureStore } from "./app/store";
import { Router } from "react-router";
import "./index.css";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import { getToken } from "./app/services/AuthService";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import { apiUrl } from "./config";
import { createMedia } from "@artsy/fresnel";

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});
const mediaStyles = AppMedia.createMediaStyle();
export const { MediaContextProvider, Media } = AppMedia;

// prepare store
const history = createBrowserHistory();
const store = configureStore();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    createUploadLink({
      uri: `${apiUrl}/graphql`,
      credentials: "same-origin",
    })
  ),
  cache: new InMemoryCache(),
  name: "admin-web",
  version: "1.0",
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={4000} />
      <Router history={history}>
        <style>{mediaStyles}</style>
        <MediaContextProvider>
          <App />
        </MediaContextProvider>
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
