import { gql } from "apollo-boost";
export const LoginQuery = gql`
  mutation Login($userName: String!, $pass: String!) {
    login(userName: $userName, password: $pass) {
      user {
        userName
        id
      }
      token
    }
  }
`;


