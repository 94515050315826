type possibleFAIcons =
  | "building outline"
  | "question circle"
  | "tree"
  | "quidditch";

export const getIconForPropertyInfo = (infoKey: string): possibleFAIcons => {
  const icon_classes: { [index: string]: string } = {
    INSURANCE_COMPANY: "building outline",
    CLEANING_COMPANY: "quidditch",
    ELEVATOR_COMPANY: "building outline",
    GARDENING_COMPANY: "tree",
  };

  for (var key in icon_classes) {
    if (infoKey.indexOf(key) === 0) {
      // Found it
      return icon_classes[key] as possibleFAIcons;
    }
  }

  return "question circle";
};
