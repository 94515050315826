import React, { useState } from "react";
import { Modal, Loader, List, Button, Form, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useLazyQuery, useMutation } from "react-apollo";

/* Internal imports */

import {
  deletePropertyInformation,
  getPropertyById,
  upsertPropertyInformation,
} from "../../queries/propertyQueries";
import PropertyContactManageModal, {
  ListContainer,
  EmptyState,
} from "./PropertyContactManageModal";
import {
  DeletePropertyInformationMutation,
  DeletePropertyInformationMutationVariables,
  PropertyByIdQuery,
  PropertyByIdQueryVariables,
  UpsertPropertyInformationMutation,
  UpsertPropertyInformationMutationVariables,
} from "../../../types";
import { getIconForPropertyInfo } from "../../utils/information_icons";
import { makeid } from "../../utils/id";

interface PropertyInformationCardModalProps {
  open: boolean;
  propertyId: string;
  propertyName: string;
  close: () => void;
  onChanged: () => void;
}

export const PropertyInformationCardModal = (
  props: PropertyInformationCardModalProps
) => {
  const [val, setVal] = useState("");
  const [title, setTitle] = useState("");
  const [key, setKey] = useState("");

  // Query for property information for fresh data.
  const [getPropertyByIdQuery, { loading, data, refetch }] = useLazyQuery<
    PropertyByIdQuery,
    PropertyByIdQueryVariables
  >(getPropertyById, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted() {},
  });

  // Mutation: Upsert information for property.
  const [upsertPropertyInformationMutation] = useMutation<
    UpsertPropertyInformationMutation,
    UpsertPropertyInformationMutationVariables
  >(upsertPropertyInformation, {
    onCompleted() {
      refetch();
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: delete information for property.
  const [deletePropertyInformationMutation] = useMutation<
    DeletePropertyInformationMutation,
    DeletePropertyInformationMutationVariables
  >(deletePropertyInformation, {
    onCompleted() {
      refetch();
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    if (props.open)
      getPropertyByIdQuery({
        variables: {
          id: props.propertyId,
        },
      });
  }, [getPropertyByIdQuery, props.open, props.propertyId]);

  const information = data?.propertyById?.information || [];

  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>Upplýsingar fyrir {props.propertyName}</Modal.Header>
      <Modal.Content>
        <ListContainer>
          <List selection verticalAlign="middle">
            {information.length > 0 ? (
              information.map((info) => {
                return (
                  <List.Item key={info.key}>
                    <List.Content floated="right" verticalAlign="middle" al>
                      <Icon
                        name="delete"
                        aria-label="Eyða upplýsingum"
                        onClick={() =>
                          deletePropertyInformationMutation({
                            variables: {
                              propertyId: props.propertyId,
                              key: info.key,
                            },
                          })
                        }
                      />
                    </List.Content>
                    <List.Icon
                      size="large"
                      verticalAlign="middle"
                      name={getIconForPropertyInfo(info.key)}
                    />
                    <List.Content>
                      <List.Header>{info.title}</List.Header>
                      <List.Description>{info.value}</List.Description>
                    </List.Content>
                  </List.Item>
                );
              })
            ) : (
              <EmptyState>
                Engar upplýsingar hafa verið skráðar fyrir húsfélagið
              </EmptyState>
            )}
          </List>
        </ListContainer>
        <Form>
          <Form.Group widths="equal">
            <Form.Select
              onChange={(e, data) =>
                setKey(data.value?.toString() || "GENERIC")
              }
              fluid
              label="Upplýsingalykill"
              placeholder="Lykill"
              options={[
                {
                  key: "Tryggingarfélag",
                  text: "Tryggingarfélag",
                  value: "INSURANCE_COMPANY",
                },
                {
                  key: "Ræstingaraðili",
                  text: "Ræstingaraðili",
                  value: "CLEANING_COMPANY",
                },
                {
                  key: "Garðaþjónusta",
                  text: "Garðaþjónusta",
                  value: "GARDENING_COMPANY",
                },
                {
                  key: "Þjónustuaðili lyftu",
                  text: "Þjónustuaðili lyftu",
                  value: "ELEVETOR_COMPANY",
                },
                {
                  key: "Almennar upplýsingar",
                  text: "Almennar upplýsingar",
                  value: "GENERIC" + makeid(5),
                },
              ]}
            />
            <Form.Input
              onChange={(e, data) => setTitle(data.value)}
              fluid
              label="Titill"
              placeholder="Titill"
              value={title}
            />
            <Form.Input
              onChange={(e, data) => setVal(data.value)}
              fluid
              label="Innihald"
              placeholder="Innihald"
              value={val}
            />
          </Form.Group>
          <Form.Button
            onClick={() => {
              upsertPropertyInformationMutation({
                variables: {
                  key,
                  title,
                  value: val,
                  propertyId: props.propertyId,
                },
              });
            }}
          >
            Bæta við í upplýsingaspjald
          </Form.Button>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} primary>
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PropertyContactManageModal;
