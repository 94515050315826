import { gql } from "apollo-boost";

/*
 * Owners API
 */
export const getOwners = gql`
  query Owners($limit: Int!, $page: Int!, $filters: OwnerFilterInput) {
    owners(limit: $limit, page: $page, filters: $filters) {
      data {
        userId
        email
        phone
        name
        socialNumber
        properties {
          id
          address
        }
      }
      totalCount
    }
  }
`;

export const getOwnersByProperty = gql`
  query OwnersByProperty($propertyId: String!) {
    ownersByProperty(propertyId: $propertyId) {
      userId
      email
      name
      phone
      socialNumber
      access
    }
  }
`;

export const getOwnerById = gql`
  query OwnerById($id: String!) {
    ownerById(id: $id) {
      userId
      email
      phone
      name
      socialNumber
    }
  }
`;

export const createOwner = gql`
  mutation CreateOwner($owner: OwnerCreateInput!) {
    createOwner(owner: $owner)
  }
`;

export const deleteOwner = gql`
  mutation DeleteOwner($ownerId: String!) {
    deleteOwnerById(id: $ownerId)
  }
`;

export const updateOwner = gql`
  mutation UpdateOwner($ownerId: String!, $owner: OwnerUpdateInput!) {
    updateOwnerById(id: $ownerId, owner: $owner)
  }
`;

/*
 *  Admins API
 */

export const getAdminUserById = gql`
  query AdminUserById($id: String!) {
    adminUserById(id: $id) {
      id
      userName
      email
      name
      role
    }
  }
`;

export const updateAdminUserById = gql`
  mutation UpdateAdminUserById($id: String!, $user: AdministratorUpdateInput!) {
    updateAdminUser(id: $id, user: $user)
  }
`;

export const updateAdminUserPassword = gql`
  mutation UpdateAdminUserPassword(
    $id: String!
    $password: String!
    $password_again: String!
  ) {
    updateAdminUserPassword(
      id: $id
      password: { password: $password, password_again: $password_again }
    )
  }
`;

export const createAdminUser = gql`
  mutation CreateAdminUser($user: AdministratorCreateInput!) {
    createAdminUser(user: $user)
  }
`;

export const deleteAdminUser = gql`
  mutation DeleteAdminUser($id: String!) {
    deleteAdminUser(id: $id)
  }
`;

export const getAdmins = gql`
  query GetAdmins {
    admins {
      id
      userName
      name
      email
      role
    }
  }
`;

export const getPreviewToken = gql`
  query GetPreviewToken($socialNumber: String!) {
    previewTokenForOwner(socialNumber: $socialNumber)
  }
`;
