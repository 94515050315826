import * as React from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";

import {
  getOwners,
  deleteOwner,
  getPreviewToken,
} from "../../queries/userQueries";
import NavHeader from "../layout/Header";
import Page from "../layout/Page";
import {
  OwnersQuery,
  DeleteOwnerMutation,
  DeleteOwnerMutationVariables,
  OwnersQueryVariables,
  GetPreviewTokenQuery,
  GetPreviewTokenQueryVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
} from "../../../types";
import {
  Table,
  Icon,
  Button,
  Header,
  Container,
  Dropdown,
  Pagination,
  Input,
} from "semantic-ui-react";
import OwnerCreateModal from "../modals/OwnerCreateModal";
import AssignToPropertyModal from "../modals/AssignToPropertyModal";
import { notEmpty } from "../../utils/array";
import { toast } from "react-toastify";
import OwnerDetailModal from "../modals/OwnerDetailModal";
import Loading from "../layout/Loading";
import styled from "styled-components";
import ErrorMessage from "../layout/ErrorMessage";
import useDebounce from "../utils/debounce";
import { useEffect } from "react";
import { getProperties } from "../../queries/propertyQueries";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const FilterItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  input {
    border-radius: 4px !important;
  }
`;

export const OwnersPage = () => {
  // Local state to keep track of modals and selected owners
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [createModalOpen, setCreateModalOpen] = React.useState<boolean>();
  const [assignModalOpen, setAssignModalOpen] = React.useState<boolean>();
  const [ownerDetailModalOpen, setOwnerDetailModalOpen] = React.useState<
    boolean
  >();
  const [selectedOwnerIndex, setSelectedOwnerIndex] = React.useState<number>();
  const [nameFilter, setNameFilter] = React.useState<string>();
  const debouncedNameFilter = useDebounce(nameFilter || "", 500);
  const [socialNumberFilter, setSocialNumberFilter] = React.useState<string>();
  const debouncedSocialNumberFilter = useDebounce(
    socialNumberFilter || "",
    500
  );
  const [emailFilter, setEmailFilter] = React.useState<string>();
  const debouncedEmailFilter = useDebounce(emailFilter || "", 500);
  const [selectedPropertyFilter, setSelectedPropertyFilter] = React.useState<
    string
  >();

  // Control to get preview token
  const [socialNumberPreview, setSocialNumberPreview] = React.useState<
    string
  >();
  const [previewToken, setPreviewToken] = React.useState<string>("");

  useEffect(() => {
    if (previewToken) {
      const win = window.open(
        `https://minn.eignarekstur.is?previewToken=${previewToken}`,
        "_blank"
      );
      win && win.focus();
    }
  }, [previewToken]);

  /* Queries */
  const { loading, error, data, refetch } = useQuery<
    OwnersQuery,
    OwnersQueryVariables
  >(getOwners, {
    variables: {
      limit: 15,
      page: selectedPage - 1,
      filters: {
        name: debouncedNameFilter,
        socialNumber: debouncedSocialNumberFilter,
        email: debouncedEmailFilter,
        property_id: selectedPropertyFilter,
      },
    },
  });

  const [previewTokenQuery] = useLazyQuery<
    GetPreviewTokenQuery,
    GetPreviewTokenQueryVariables
  >(getPreviewToken, {
    variables: {
      socialNumber: socialNumberPreview || "",
    },
    onCompleted(response) {
      setPreviewToken(response.previewTokenForOwner);
    },
  });

  // Query: Get Properties
  const propertiesDataQuery = useQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    variables: {
      limit: 3000, // TODO: Create a search for this instead.
      page: 0,
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  /* Mutations */

  // Mutation: Delete Owners.
  const [deleteOwnerMutation] = useMutation<
    DeleteOwnerMutation,
    DeleteOwnerMutationVariables
  >(deleteOwner, {
    onCompleted() {
      setCreateModalOpen(false);
      toast.success("Aðgerð heppnaðist: Eiganda eytt.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  // Render

  let owners = (data && data.owners && data.owners.data) || [];
  let ownersCount = data && data.owners ? data.owners.totalCount : 0;
  let totalPages = Math.ceil((ownersCount || 0) / 15);
  let selectedOwner =
    (selectedOwnerIndex !== undefined && owners[selectedOwnerIndex]) ||
    undefined;
  let selecteOwnersProperties =
    (selectedOwner &&
      selectedOwner.properties &&
      selectedOwner.properties.filter(notEmpty)) ||
    [];

  if (error) return <h1>ERROR</h1>;

  return (
    <>
      <OwnerCreateModal
        open={createModalOpen || false}
        close={() => setCreateModalOpen(false)}
        onUpdate={() => refetch()}
      ></OwnerCreateModal>

      <AssignToPropertyModal
        open={assignModalOpen || false}
        loading={false}
        properties={selecteOwnersProperties}
        ownerId={(selectedOwner && selectedOwner.userId) || undefined}
        close={() => setAssignModalOpen(false)}
        refetch={() => refetch()}
      />

      <OwnerDetailModal
        open={ownerDetailModalOpen || false}
        ownerId={(selectedOwner && selectedOwner.userId) || ""}
        close={() => setOwnerDetailModalOpen(false)}
        onUpdated={() => {
          refetch();
        }}
      />

      <NavHeader />
      <Page>
        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Íbúar á skrá</Header>
          <FilterWrapper>
            <FilterItem>
              <Input
                loading={loading}
                icon="user outline"
                value={nameFilter}
                placeholder="Leit eftir nafni"
                onChange={(_, { value }) => {
                  setSelectedPage(1); // Reset pagination.
                  setNameFilter(value.toString());
                }}
              />
            </FilterItem>
            <FilterItem>
              <Input
                loading={loading}
                value={socialNumberFilter}
                icon="address card outline"
                placeholder="Leit eftir kennitölu"
                onChange={(_, { value }) => {
                  setSelectedPage(1); // Reset pagination.
                  setSocialNumberFilter(value.toString());
                }}
              />
            </FilterItem>
            <FilterItem>
              <Input
                loading={loading}
                icon="mail"
                value={emailFilter}
                placeholder="Leit eftir póstfangi"
                onChange={(_, { value }) => {
                  setSelectedPage(1); // Reset pagination.
                  setEmailFilter(value.toString());
                }}
              />
            </FilterItem>
            <FilterItem>
              <Dropdown
                placeholder="Leita í húsfélagi"
                search
                clearable
                selection
                onChange={(_, { value }) => {
                  setSelectedPage(1);
                  setSelectedPropertyFilter(value as string | undefined);
                }}
                options={propertiesDataQuery?.data?.properties.data.map(
                  (property) => {
                    return {
                      key: property?.id || "",
                      text: property?.address || "",
                      value: property?.id || "",
                    };
                  }
                )}
              />
            </FilterItem>
          </FilterWrapper>
          {error ? (
            <ErrorMessage />
          ) : (
            <Table padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Nafn</Table.HeaderCell>
                  <Table.HeaderCell>Kennitala</Table.HeaderCell>
                  <Table.HeaderCell>E-mail</Table.HeaderCell>
                  <Table.HeaderCell>Sími</Table.HeaderCell>
                  <Table.HeaderCell>Húsfélög</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {loading ? (
                  <Loading />
                ) : owners ? (
                  owners.map((owner, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{owner && owner.name}</Table.Cell>
                      <Table.Cell>{owner && owner.socialNumber}</Table.Cell>
                      <Table.Cell>{owner && owner.email}</Table.Cell>
                      <Table.Cell>{owner && owner.phone}</Table.Cell>
                      <Table.Cell>
                        {(owner &&
                          owner.properties &&
                          owner.properties.length) ||
                          0}
                      </Table.Cell>
                      <Table.Cell>
                        <Dropdown text="Aðgerðir">
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon="home"
                              text="Aðgengi að húsfélögum"
                              onClick={() => {
                                setSelectedOwnerIndex(index);
                                setAssignModalOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon="user"
                              text="Breyta upplýsingum"
                              onClick={() => {
                                setSelectedOwnerIndex(index);
                                setOwnerDetailModalOpen(true);
                              }}
                            />
                            <Dropdown.Item
                              icon="user"
                              text="Skoða mínar síður sem íbúi"
                              onClick={() => {
                                setSocialNumberPreview(owner.socialNumber);
                                previewTokenQuery();
                              }}
                            />
                            <Dropdown.Item
                              disabled
                              icon="money"
                              text="Skoða reikninga"
                              onClick={() => {}}
                            />
                            <Dropdown.Divider />
                            <Dropdown.Item
                              icon="delete"
                              text="Eyða eiganda."
                              onClick={() => {
                                deleteOwnerMutation({
                                  variables: {
                                    ownerId: (owner && owner.userId) || "",
                                  },
                                }).then(() => refetch());
                              }}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>Enginn eigandi fannst</Table.Row>
                )}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="3" textAlign="left">
                    <Pagination
                      defaultActivePage={1}
                      totalPages={totalPages}
                      onPageChange={(e, data) => {
                        const nextPage = data.activePage;
                        console.log(nextPage);
                        setSelectedPage(
                          nextPage ? parseInt(nextPage.valueOf().toString()) : 1
                        );
                      }}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="3">
                    <Button.Group floated="right">
                      <Button
                        icon
                        primary
                        size="small"
                        onClick={() => setCreateModalOpen(true)}
                      >
                        <Icon name="user" /> Búa til íbúa
                      </Button>
                      <Button
                        icon
                        floated="right"
                        size="small"
                        onClick={() => refetch()}
                      >
                        <Icon name="refresh" /> Endursækja
                      </Button>
                    </Button.Group>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </Container>
      </Page>
    </>
  );
};

export default OwnersPage;
