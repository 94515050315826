import React from "react";

/** Internal imports */
import NavHeader from "../../layout/Header";
import Page from "../../layout/Page";
import {
  Button,
  Container,
  Header,
  Icon,
  Input,
  Table,
} from "semantic-ui-react";
import styled from "styled-components";
import useDebounce from "../../utils/debounce";
import Loading from "../../layout/Loading";
import IncidentsPage from "../IncidentPage";
import { useQuery } from "react-apollo";
import { getPropertContacts } from "../../../queries/propertyContactsQueries";
import {
  PropertyContactsQuery,
  PropertyContactsQueryVariables,
} from "../../../../types";
import { format, parseISO } from "date-fns";
import {
  exportPropertyContactsToCSV,
  saveAsBlob,
} from "../../../utils/export/propertyContactExport";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const FilterItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  input {
    border-radius: 4px !important;
  }
`;

export const PropertyContactsPage = () => {
  const [roleFilter, setRoleFilter] = React.useState<string>("");
  const debouncedRoleFilter = useDebounce(roleFilter || "", 500);

  /* Queries */
  const { loading, data } = useQuery<
    PropertyContactsQuery,
    PropertyContactsQueryVariables
  >(getPropertContacts, {
    variables: {
      role: debouncedRoleFilter,
    },
  });

  return (
    <>
      <NavHeader />
      <Page>
        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Tengiliðir húsfélaga</Header>
          <FilterWrapper>
            <FilterItem>
              <Input
                loading={loading}
                icon="address card outline"
                value={roleFilter}
                placeholder="Leit eftir hlutverki"
                onChange={(_, { value }) => {
                  setRoleFilter(value.toString());
                }}
              />{" "}
            </FilterItem>
            <Button
              icon
              disabled={data?.propertyContacts.length === 0}
              secondary
              size="small"
              onClick={() => {
                saveAsBlob(
                  exportPropertyContactsToCSV(data?.propertyContacts || []),
                  "Contacts Export"
                );
              }}
            >
              <Icon name="file excel" /> Sækja gögn
            </Button>
          </FilterWrapper>
          <Table padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nafn</Table.HeaderCell>
                <Table.HeaderCell>Hlutverk</Table.HeaderCell>
                <Table.HeaderCell>Símanúmer</Table.HeaderCell>
                <Table.HeaderCell>Tölvupóstur</Table.HeaderCell>
                <Table.HeaderCell>Húsfélag</Table.HeaderCell>
                <Table.HeaderCell>Síðast breytt</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {loading ? (
                <Loading />
              ) : data ? (
                data.propertyContacts.map((propertyContact) => (
                  <Table.Row key={propertyContact.id}>
                    <Table.Cell>
                      {propertyContact && propertyContact.name}
                    </Table.Cell>
                    <Table.Cell>
                      {propertyContact && propertyContact.role}
                    </Table.Cell>
                    <Table.Cell>
                      {propertyContact && propertyContact.phone}
                    </Table.Cell>
                    <Table.Cell>
                      {propertyContact && propertyContact.email}
                    </Table.Cell>
                    <Table.Cell>
                      {propertyContact && propertyContact.propertyName}
                    </Table.Cell>
                    <Table.Cell>
                      {propertyContact &&
                        format(
                          parseISO(propertyContact.modified),
                          "dd.MM.yyyy hh:mm"
                        )}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  Engin niðurstaða, vinsamlega leitaðu eftir hlutverki.
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Container>
      </Page>
    </>
  );
};

export default IncidentsPage;
