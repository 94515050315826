import * as React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import is from "date-fns/locale/is";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import {
  DocumentByIdQuery,
  DocumentByIdQueryVariables,
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables,
} from "../../../types";
import { useMutation, useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { getDocumentById, updateDocument } from "../../queries/documentQueries";
import OwnerDetailModal from "./OwnerDetailModal";
import { getKnownDocumentTypesOptions } from "../../utils/document";

registerLocale("is", is);

interface DocumentDetailModalProps {
  open: boolean;
  documentId?: string;
  close: () => void;
  onUpdated: () => void;
}

/*
 * DocumentDetailModal shows detailed information about a document,
 * and allows admins to update information.
 */
export const DocumentDetailModal = (props: DocumentDetailModalProps) => {
  // Local state to keep track of changes.
  const [id, setId] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [description, setDescription] = React.useState<string>();
  const [type, setType] = React.useState<string>();
  const [mimetype, setMimetype] = React.useState<string>();
  const [createdDate, setCreatedDate] = React.useState<string>();
  const [modifiedDate, setModifiedDate] = React.useState<string>();
  const [displayDate, setDisplayDate] = React.useState<Date>(new Date());

  /* Queries */

  // Query: Get Document By Id.
  const [getDocumentByIdQuery, { loading, data }] = useLazyQuery<
    DocumentByIdQuery,
    DocumentByIdQueryVariables
  >(getDocumentById, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted(data) {
      setId((data.documentById && data.documentById.id) || "");
      setName((data.documentById && data.documentById.name) || "");
      setDescription(
        (data.documentById && data.documentById.description) || ""
      );
      setCreatedDate(
        (data.documentById && data.documentById.createdDate) || ""
      );
      setModifiedDate(
        (data.documentById && data.documentById.modifiedDate) || ""
      );
      setType((data.documentById && data.documentById.type) || "");
      setMimetype((data.documentById && data.documentById.mimetype) || "");
      setDisplayDate(
        data.documentById.displayDate
          ? new Date(data.documentById.displayDate)
          : new Date()
      );
    },
  });

  /* Mutations */

  // Mutation: Update Document By Id.
  const [updateDocumentMutation] = useMutation<
    UpdateDocumentMutation,
    UpdateDocumentMutationVariables
  >(updateDocument, {
    onCompleted() {
      toast.success("Aðgerð heppnaðist: Skjal uppfært");
    },
    onError(...error) {
      toast.error("Aðgerð heppnaðist ekki: " + error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    props.documentId &&
      getDocumentByIdQuery({
        variables: {
          id: props.documentId,
        },
      });
  }, [props.documentId, getDocumentByIdQuery]);

  /* Helper functions */

  // isValidToChange decides if we should allow to save details.
  const isValidToChange = () => {
    return (
      name &&
      name !== "" &&
      type &&
      type !== "" &&
      data &&
      data.documentById &&
      (data.documentById.description !== description ||
        data.documentById.name !== name ||
        data.documentById.type !== type ||
        data.documentById.displayDate !== displayDate.toISOString())
    );
  };

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>{name}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Einkenni</label>
            <input
              readOnly={true}
              disabled={true}
              value={id}
              placeholder="Nafn"
            />
          </Form.Field>
          <Form.Field>
            <label>Skráartýpa</label>
            <input
              readOnly={true}
              disabled={true}
              value={mimetype}
              placeholder="Skráartýpa"
            />
          </Form.Field>
          <Form.Field>
            <label>Búið til</label>
            <input
              readOnly={true}
              disabled={true}
              value={createdDate}
              placeholder="Búið til"
            />
          </Form.Field>
          <Form.Field>
            <label>Síðast breytt</label>
            <input
              readOnly={true}
              disabled={true}
              value={modifiedDate}
              placeholder="Síðast breytt"
            />
          </Form.Field>
          <Form.Field>
            <label>Dagsetning skjals</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              locale="is"
              selected={displayDate}
              onChange={(v) => setDisplayDate(v || new Date())}
            />
          </Form.Field>
          <Form.Field>
            <label>Nafn</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nafn"
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              fluid
              label="Flokkur"
              value={type}
              options={getKnownDocumentTypesOptions()}
              onChange={(e, { value }) =>
                setType(value ? value.toString() : "")
              }
              placeholder="Flokkur"
            />
          </Form.Field>
          <Form.Field>
            <label>Skýring</label>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Skýring"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            // Update the owner
            updateDocumentMutation({
              variables: {
                id: id || "",
                document: {
                  name: name || "",
                  description: description || "",
                  type: type || "",
                  displayDate: displayDate.toISOString() || "",
                },
              },
            }).then(() => {
              // Close when succesfully updated.
              props.close();
              // Trigger update event
              props.onUpdated();
            });
          }}
          disabled={!isValidToChange()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Vista breytingar"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default OwnerDetailModal;
