import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { PropertyContact } from "../../../types";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

/**
 * Generate an Excel workbook containing property contacts
 * @param arrData property contacts array
 * @returns Blob of an Excel workbook
 */
export const exportPropertyContactsToCSV = (
  arrData: PropertyContact[]
): Blob => {
  // Calculate max width of columns
  const userIdWidth = arrData.reduce((w, r) => Math.max(w, r.name.length), 10);
  const emailWidth = arrData.reduce(
    (w, r) => Math.max(w, r.email?.length || 0),
    10
  );
  const nameWidth = arrData.reduce((w, r) => Math.max(w, r.name.length), 10);
  const phoneWidth = arrData.reduce(
    (w, r) => Math.max(w, r.phone?.length || 0),
    10
  );
  const socialWidth = arrData.reduce(
    (w, r) => Math.max(w, r.role?.length || 0),
    10
  );
  const accessWidth = arrData.reduce(
    (w, r) => Math.max(w, r.propertyName.length),
    10
  );

  // Create a sheet
  const ws = XLSX.utils.json_to_sheet(
    arrData.map(({ __typename, id, created, propertyId, ...rest }) => {
      return rest;
    })
  );

  // Resize all columns to max width
  ws["!cols"] = [
    { wch: userIdWidth },
    { wch: emailWidth },
    { wch: nameWidth },
    { wch: phoneWidth },
    { wch: socialWidth },
    { wch: accessWidth },
  ];

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Append sheet to workbook
  XLSX.utils.book_append_sheet(workbook, ws, "Contacts");

  // Create a Excel buffer
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a Blob from buffer
  const data = new Blob([excelBuffer], { type: fileType });

  // Return blob
  return data;
};

// Trigger as download of the Blob object in browsers.
export const saveAsBlob = (data: Blob, fileName: string) =>
  FileSaver.saveAs(data, fileName + fileExtension);
