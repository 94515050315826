import * as React from "react";
import styled from "styled-components";

interface PageProps {
  className?: string;
}

const Root: React.SFC<PageProps> = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);

export default Root;

const Wrapper = styled.div`
  margin-top: 100px;
  padding: 10px;
`;
