type possibleFAIcons =
  | "file image"
  | "file audio"
  | "file video"
  | "file pdf"
  | "file word"
  | "file excel"
  | "file powerpoint"
  | "file text"
  | "file code"
  | "file archive"
  | "file";

export const getFontAwesomeIconFromMIME = (
  mimeType: string
): possibleFAIcons => {
  // List of official MIME Types: http://www.iana.org/assignments/media-types/media-types.xhtml
  const icon_classes: { [index: string]: string } = {
    // Media
    image: "file image",
    audio: "file audio",
    video: "file video",
    // Documents
    "application/pdf": "file pdf",
    "application/msword": "file word",
    "application/vnd.ms-word": "file word",
    "application/vnd.oasis.opendocument.text": "file word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml":
      "file word",
    "application/vnd.ms-excel": "file excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml":
      "file excel",
    "application/vnd.oasis.opendocument.spreadsheet": "file excel",
    "application/vnd.ms-powerpoint": "file powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml":
      "file powerpoint",
    "application/vnd.oasis.opendocument.presentation": "file powerpoint",
    "text/plain": "file text",
    "text/html": "file code",
    "application/json": "file code",
    // Archives
    "application/gzip": "file archive",
    "application/zip": "file archive"
  };

  for (var key in icon_classes) {
    if (mimeType.indexOf(key) === 0) {
      // Found it
      return icon_classes[key] as possibleFAIcons;
    }
  }

  return "file";
};
