import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const { loggedIn } = useSelector((state: AppState) => state.system);

  return (
    <Route
      {...rest}
      render={routeProps =>
        loggedIn ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
