import { gql } from "apollo-boost";

export const getDocuments = gql`
  query Documents($limit: Int!, $page: Int!, $filters: DocumentFilterInput) {
    documents(limit: $limit, page: $page, filters: $filters) {
      data {
        id
        name
        type
        mimetype
        description
        createdDate
        modifiedDate
        displayDate
        properties {
          property {
            id
            address
          }
          accessType
        }
      }
      totalCount
    }
  }
`;

export const getDocumentById = gql`
  query DocumentById($id: String!) {
    documentById(id: $id) {
      id
      name
      type
      mimetype
      description
      createdDate
      modifiedDate
      displayDate
      properties {
        property {
          id
          address
        }
        accessType
      }
    }
  }
`;

export const uploadDocuments = gql`
  mutation UploadDocuments(
    $files: [Upload!]!
    $type: String!
    $description: String
  ) {
    uploadDocuments(files: $files, type: $type, description: $description) {
      id
      name
      type
      mimetype
      description
      displayDate
    }
  }
`;

export const uploadDocumentsV2 = gql`
  mutation UploadDocumentsV2(
    $files: [Upload!]!
    $type: String!
    $description: String
  ) {
    uploadDocumentsV2(files: $files, type: $type, description: $description) {
      id
      name
      type
      mimetype
      description
      displayDate
    }
  }
`;

export const updateDocument = gql`
  mutation UpdateDocument($id: String!, $document: DocumentInput!) {
    updateDocument(id: $id, document: $document)
  }
`;

export const deleteDocument = gql`
  mutation DeleteDocument($id: String!) {
    deleteDocument(id: $id, cascadeDelete: true)
  }
`;

export const assignDocumentToProperty = gql`
  mutation assignDocumentToProperty(
    $propertyId: String!
    $documentId: String!
    $note: String
    $accessType: DocumentAccessType!
  ) {
    assignDocumentToProperty(
      propertyId: $propertyId
      documentId: $documentId
      assignmentNote: $note
      accessType: $accessType
    )
  }
`;

export const unassignDocumentFromProperty = gql`
  mutation UnassignDocumentFromProperty(
    $propertyId: String!
    $documentId: String!
  ) {
    unassignDocumentFromProperty(
      propertyId: $propertyId
      documentId: $documentId
    )
  }
`;
