import {
    SystemState,
    SystemActionTypes,
  } from './types'
import { Reducer } from 'redux';
import { isLoggedIn, getUserIdFromPayload } from '../../services/AuthService';

  const initialState: SystemState = {
    loggedIn: isLoggedIn(),
    loggedInUserId: getUserIdFromPayload(),
  }
  
  const reducer: Reducer<SystemState> = (state = initialState, action) => {
    switch (action.type) {
      case SystemActionTypes.UPDATE_SESSION: {
        return {
          ...state,
          ...action.payload
        }
      }
      case SystemActionTypes.CLEAR_SESSION: {
        return {
          loggedIn: false,
          userName: ''
        }
      }
      default:
        return state
    }
  }

  export { reducer as systemReducer }

