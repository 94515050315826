import { gql } from "apollo-boost";

export const getIncidents = gql`
  query GetIncidents(
    $limit: Int!
    $page: Int!
    $filters: IncidentsFilterInput
  ) {
    incidents(limit: $limit, page: $page, filters: $filters) {
      totalCount
      data {
        id
        authorId
        propertyId
        reporterOwnerId
        reporterOther
        title
        category
        numberReference
        body
        created
        reporterOwner {
          name
        }
        property {
          address
        }
      }
    }
  }
`;

export const getIncident = gql`
  query GetIncident($id: String!) {
    incident(incidentId: $id) {
      id
      authorId
      author {
        name
      }
      propertyId
      reporterOwnerId
      reporterOther
      title
      category
      numberReference
      body
      created
      reporterOwner {
        name
      }
      property {
        address
      }
    }
  }
`;

export const createIncident = gql`
  mutation CreateIncident($input: IncidentInput) {
    createIncident(input: $input) {
      id
      authorId
      propertyId
      reporterOwnerId
      reporterOther
      title
      category
      numberReference
      body
      created
    }
  }
`;

export const deleteIncident = gql`
  mutation DeleteIncident($deleteIncidentId: String!) {
    deleteIncident(id: $deleteIncidentId)
  }
`;
