import React from "react";

/** Internal imports */
import NavHeader from "../layout/Header";
import Page from "../layout/Page";
import { Container, Header, HeaderSubheader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Grid = styled.div`
  padding: 32px 0;
  display: grid;
  grid-template-columns: repeat(2, 240px);
  grid-gap: 32px;
  position: relative;
  z-index: 1;

  h4 {
    z-index: 2;
    position: relative;
    margin: 12px 0 4px 0;
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    line-height: 2;
    color: #000000;
  }

  p {
    z-index: 2;
    position: relative;
    margin: 0;
    font-size: 14px;
    line-height: 1.7;
    color: #191919;
  }

  .shine {
    border-radius: inherit;
    position: absolute;
    inset: 0;
    z-index: 1;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s;

    &:before {
      content: "";
      width: 150%;
      padding-bottom: 150%;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      bottom: 55%;
      filter: blur(35px);
      opacity: 0.1;
      transform: translateX(-50%);
      background-image: conic-gradient(
        from 205deg at 50% 50%,
        rgba(16, 185, 129, 0) 0deg,
        #10b981 25deg,
        rgba(52, 211, 153, 0.18) 295deg,
        rgba(16, 185, 129, 0) 360deg
      );
    }
  }

  .background {
    border-radius: inherit;
    position: absolute;
    inset: 0;
    overflow: hidden;
    -webkit-mask-image: radial-gradient(
      circle at 60% 5%,
      black 0%,
      black 15%,
      transparent 60%
    );
    mask-image: radial-gradient(
      circle at 60% 5%,
      black 0%,
      black 15%,
      transparent 60%
    );

    .tiles {
      opacity: 0;
      transition: opacity 0.25s;

      .tile {
        position: absolute;
        background-color: rgba(16, 185, 129, 0.08);
        animation-duration: 8s;
        animation-iteration-count: infinite;
        opacity: 0;

        &.tile-4,
        &.tile-6,
        &.tile-10 {
          animation-delay: -2s;
        }

        &.tile-3,
        &.tile-5,
        &.tile-8 {
          animation-delay: -4s;
        }

        &.tile-2,
        &.tile-9 {
          animation-delay: -6s;
        }

        &.tile-1 {
          top: 0;
          left: 0;
          height: 10%;
          width: 22.5%;
        }

        &.tile-2 {
          top: 0;
          left: 22.5%;
          height: 10%;
          width: 27.5%;
        }

        &.tile-3 {
          top: 0;
          left: 50%;
          height: 10%;
          width: 27.5%;
        }

        &.tile-4 {
          top: 0;
          left: 77.5%;
          height: 10%;
          width: 22.5%;
        }

        &.tile-5 {
          top: 10%;
          left: 0;
          height: 22.5%;
          width: 22.5%;
        }

        &.tile-6 {
          top: 10%;
          left: 22.5%;
          height: 22.5%;
          width: 27.5%;
        }

        &.tile-7 {
          top: 10%;
          left: 50%;
          height: 22.5%;
          width: 27.5%;
        }

        &.tile-8 {
          top: 10%;
          left: 77.5%;
          height: 22.5%;
          width: 22.5%;
        }

        &.tile-9 {
          top: 32.5%;
          left: 50%;
          height: 22.5%;
          width: 27.5%;
        }

        &.tile-10 {
          top: 32.5%;
          left: 77.5%;
          height: 22.5%;
          width: 22.5%;
        }
      }
    }

    @keyframes tile {
      0%,
      12.5%,
      100% {
        opacity: 1;
      }

      25%,
      82.5% {
        opacity: 0;
      }
    }

    .line {
      position: absolute;
      inset: 0;
      opacity: 0;
      transition: opacity 0.35s;

      &:before,
      &:after {
        content: "";
        position: absolute;
        background-color: #e9e9e7;
        transition: transform 0.35s;
      }

      &:before {
        left: 0;
        right: 0;
        height: 1px;
        transform-origin: 0 50%;
        transform: scaleX(0);
      }

      &:after {
        top: 0;
        bottom: 0;
        width: 1px;
        transform-origin: 50% 0;
        transform: scaleY(0);
      }

      &.line-1 {
        &:before {
          top: 10%;
        }

        &:after {
          left: 22.5%;
        }

        &:before,
        &:after {
          transition-delay: 0.3s;
        }
      }

      &.line-2 {
        &:before {
          top: 32.5%;
        }

        &:after {
          left: 50%;
        }

        &:before,
        &:after {
          transition-delay: 0.15s;
        }
      }

      &.line-3 {
        &:before {
          top: 55%;
        }

        &:after {
          right: 22.5%;
        }
      }
    }
  }
`;

const Card = styled.div`
  background-color: #fdfdfd;
  box-shadow: 0px 3px 6px rgba(24, 24, 27, 0.02),
    0px 2px 7px rgba(24, 24, 27, 0.04), 0 0 0 1px rgba(24, 24, 27, 0.08);
  padding: 56px 16px 16px 16px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.25s;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    background-color: transparent;
  }

  &:hover {
    box-shadow: 0px 3px 6px rgba(24, 24, 27, 0.05),
      0px 8px 15px rgba(24, 24, 27, 0.1), 0 0 0 1px rgba(24, 24, 27, 0.34);

    .icon {
      &::after {
        background-color: rgba(104, 104, 112, 0.04);
        border-color: rgba(255, 255, 255, 0.34);
      }

      svg {
        color: #6f6f74;
      }
    }

    .shine {
      opacity: 1;
      transition-duration: 0.5s;
      transition-delay: 0s;
    }

    .background {
      .tiles {
        opacity: 1;
        transition-delay: 0.25s;

        .tile {
          animation-name: tile;
        }
      }

      .line {
        opacity: 1;
        transition-duration: 0.15s;

        &:before {
          transform: scaleX(1);
        }

        &:after {
          transform: scaleY(1);
        }

        &.line-1 {
          &:before,
          &:after {
            transition-delay: 0s;
          }
        }

        &.line-2 {
          &:before,
          &:after {
            transition-delay: 0.15s;
          }
        }

        &.line-3 {
          &:before,
          &:after {
            transition-delay: 0.3s;
          }
        }
      }
    }
  }

  .icon {
    z-index: 2;
    position: relative;
    display: table;
    padding: 8px;

    &::after {
      content: "";
      position: absolute;
      inset: 4.5px;
      border-radius: 50%;
      background-color: rgba(24, 24, 27, 0.04);
      border: 1px solid rgba(24, 24, 27, 0.1);
      backdrop-filter: blur(2px);
      transition: background-color 0.25s, border-color 0.25s;
    }

    svg {
      position: relative;
      z-index: 1;
      display: block;
      width: 24px;
      height: 24px;
      transform: translateZ(0);
      color: #18181b;
      transition: color 0.25s;
    }
  }
`;

export const ReportsPage = () => {
  return (
    <>
      <NavHeader />
      <Page>
        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Skýrslur</Header>
          <HeaderSubheader>
            Taka út skýrslur úr gögnum Eignareksturs
          </HeaderSubheader>
          <Grid>
            <Link to="/reports/contacts">
              <Card>
                <span className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    width="32px"
                    height="32px"
                  >
                    <path d="M 16 4 C 12.144531 4 9 7.144531 9 11 C 9 13.394531 10.21875 15.519531 12.0625 16.78125 C 8.484375 18.304688 6 21.859375 6 26 L 8 26 C 8 21.535156 11.535156 18 16 18 C 20.464844 18 24 21.535156 24 26 L 26 26 C 26 21.859375 23.515625 18.304688 19.9375 16.78125 C 21.78125 15.519531 23 13.394531 23 11 C 23 7.144531 19.855469 4 16 4 Z M 16 6 C 18.773438 6 21 8.226563 21 11 C 21 13.773438 18.773438 16 16 16 C 13.226563 16 11 13.773438 11 11 C 11 8.226563 13.226563 6 16 6 Z" />
                  </svg>
                </span>

                <h4>Tengiliðir Húsfélaga</h4>

                <p>Taktu út skýrslur fyrir tengiliði húsfélaga.</p>
                <div className="shine"></div>
                <div className="background">
                  <div className="tiles">
                    <div className="tile tile-1"></div>
                    <div className="tile tile-2"></div>
                    <div className="tile tile-3"></div>
                    <div className="tile tile-4"></div>

                    <div className="tile tile-5"></div>
                    <div className="tile tile-6"></div>
                    <div className="tile tile-7"></div>
                    <div className="tile tile-8"></div>

                    <div className="tile tile-9"></div>
                    <div className="tile tile-10"></div>
                  </div>

                  <div className="line line-1"></div>
                  <div className="line line-2"></div>
                  <div className="line line-3"></div>
                </div>
              </Card>
            </Link>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default ReportsPage;
