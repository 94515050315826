import * as React from "react";
import { registerLocale } from "react-datepicker";
import is from "date-fns/locale/is";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import { GetIncidentQuery, GetIncidentQueryVariables } from "../../../types";
import { useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { getIncident } from "../../queries/crmQueries";

registerLocale("is", is);

interface IncidentDetailsModalProps {
  open: boolean;
  incidentId?: string;
  close: () => void;
}

/*
 * DocumentDetailModal shows detailed information about a document,
 * and allows admins to update information.
 */
export const IncidentDetailsModal = (props: IncidentDetailsModalProps) => {
  // Local state to keep track of changes.
  const [author, setAuthor] = React.useState<string>();
  const [body, setBody] = React.useState<string>();
  const [category, setCategory] = React.useState<string>();
  const [title, setTitle] = React.useState<string>();
  const [createdDate, setCreatedDate] = React.useState<string>();
  const [id, setId] = React.useState<string>();
  const [numberReference, setNumberReference] = React.useState<string>();
  const [address, setAddress] = React.useState<string>();
  const [reporterOther, setReporterOther] = React.useState<string>();
  const [reporterOwnerName, setReporterOwnerName] = React.useState<string>();

  /* Queries */

  // Query: Get Document By Id.
  const [getIncidentQuery, { loading }] = useLazyQuery<
    GetIncidentQuery,
    GetIncidentQueryVariables
  >(getIncident, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted(data) {
      setAuthor(data.incident.author.name || "");
      setTitle(data.incident.title || "");
      setBody(data.incident.body || "");
      setCategory(data.incident.category || "");
      setCreatedDate(data.incident.created || "");
      setId(data.incident.id || "");
      setNumberReference(data.incident.numberReference || "");
      setAddress(data.incident.property.address || "");
      setReporterOther(data.incident?.reporterOther || "");
      setReporterOwnerName(data.incident?.reporterOwner?.name || "");
    },
  });

  // Effects
  React.useEffect(() => {
    props.incidentId &&
      getIncidentQuery({
        variables: {
          id: props.incidentId,
        },
      });
  }, [props.incidentId, getIncidentQuery]);

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>ID</label>
            <input
              readOnly={true}
              disabled={true}
              value={id}
              placeholder="ID"
            />
          </Form.Field>
          <Form.Field>
            <label>Skráð af</label>
            <input
              readOnly={true}
              disabled={true}
              value={author}
              placeholder="Author"
            />
          </Form.Field>
          <Form.Field>
            <label>Titill</label>
            <input
              readOnly={true}
              disabled={true}
              value={title}
              placeholder="Titill"
            />
          </Form.Field>
          <Form.Field>
            <label>Búið til</label>
            <input
              readOnly={true}
              disabled={true}
              value={createdDate}
              placeholder="Búið til"
            />
          </Form.Field>
          <Form.Field>
            <label>Húsfélag</label>
            <input
              readOnly={true}
              disabled={true}
              value={address}
              placeholder="Húsfélag"
            />
          </Form.Field>
          <Form.Field>
            <label>Flokkur</label>
            <input
              readOnly={true}
              disabled={true}
              value={category}
              placeholder="Flokkur"
            />
          </Form.Field>

          <Form.Field>
            <label>Málsnúmer</label>
            <input
              value={numberReference}
              placeholder="Málsnúmer"
              disabled={true}
              readOnly={true}
            />
          </Form.Field>

          <Form.Field>
            <label>Tengiliður Íbúi</label>
            <input
              value={reporterOther}
              placeholder="Tengiliður Íbúi"
              disabled={true}
              readOnly={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Annar Tengiliður</label>
            <input
              value={reporterOwnerName}
              placeholder="Annar Tengiliður"
              disabled={true}
              readOnly={true}
            />
          </Form.Field>
          <Form.Field>
            <label>Útskýring</label>
            <textarea
              value={body}
              placeholder="Útskýring"
              disabled={true}
              readOnly={true}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default IncidentDetailsModal;
