import * as React from "react";
import { useQuery } from "react-apollo";

import { getStatistics } from "../../queries/statisticsQueries";
import NavHeader from "../layout/Header";
import Page from "../layout/Page";
import { Grid, Segment, Header, Statistic, Loader } from "semantic-ui-react";
import { StatisticsQuery, StatisticsQueryVariables } from "../../../types";
import { toast } from "react-toastify";
import { startOfMonth } from "date-fns";
import Loading from "../layout/Loading";

var auditsAfter = startOfMonth(new Date()); // Starting of the month

const DashPage = () => {
  const { loading, data } = useQuery<StatisticsQuery, StatisticsQueryVariables>(
    getStatistics,
    {
      variables: {
        fromDate: auditsAfter.toISOString()
      },
      onError(...error) {
        toast.error(error.map(x => x.message)[0]);
      }
    }
  );

  return loading ? (
    <Loading />
  ) : (
    <>
      <NavHeader />
      <Page>
        <Loader active={loading} />
        <Grid container columns={1} style={{ maxWidth: 800 }}>
          <Header as="h1">Mælaborð</Header>
        </Grid>
        <Grid container columns={3} stackable>
          <Grid.Column>
            <Header as="h3">Íbúar</Header>
            <Segment>
              <Statistic>
                <Statistic.Value>
                  {(data && data.owners && data.owners.totalCount) || 0}
                </Statistic.Value>
                <Statistic.Label>Íbúar á skrá</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">Húsfélög</Header>
            <Segment>
              <Statistic>
                <Statistic.Value>
                  {(data && data.properties && data.properties.totalCount) || 0}
                </Statistic.Value>
                <Statistic.Label>Húsfélög á skrá</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">Skjöl</Header>
            <Segment>
              <Statistic>
                <Statistic.Value>
                  {(data &&
                    data.documentsStatistics &&
                    data.documentsStatistics.count) ||
                    0}
                </Statistic.Value>
                <Statistic.Label>Vistuð skjöl</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Header as="h3">Íbúar innskráðir</Header>
            <Segment>
              <Statistic>
                <Statistic.Value>
                  {data?.audits?.totalCount || 0}
                </Statistic.Value>
                <Statistic.Label>líðandi mánuð</Statistic.Label>
              </Statistic>
            </Segment>
          </Grid.Column>
        </Grid>
      </Page>
    </>
  );
};

export default DashPage;
