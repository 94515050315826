import * as React from "react";
import {
  Modal,
  Loader,
  Button,
  Form,
  List,
  Image,
  Icon,
} from "semantic-ui-react";
import {
  getPropertyContactByPropertyId,
  createPropertyContact,
  deletePropertyContact,
  updatePropertyContact,
} from "../../queries/propertyQueries";
import {
  PropertyContactByPropertyIdQueryVariables,
  PropertyContactByPropertyIdQuery,
  CreatePropertyContactMutation,
  CreatePropertyContactMutationVariables,
  DeletePropertyContactMutation,
  DeletePropertyContactMutationVariables,
  UpdatePropertyContactMutation,
  UpdatePropertyContactMutationVariables,
} from "../../../types";
import { useLazyQuery, useMutation } from "react-apollo";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useState } from "react";

interface PropertyContactManageModalProps {
  open: boolean;
  propertyId: string;
  propertyName: string;
  close: () => void;
  onChanged: () => void;
}

export const EmptyState = styled.div`
  text-align: center;
`;
export const ListContainer = styled.div`
  border-bottom: 2px solid #dadada;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const PropertyContactManageModal = (
  props: PropertyContactManageModalProps
) => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [editContact, setEditContact] = useState<{
    id: string;
    name: string;
    role: string;
    email: string;
    phone: string;
  }>();

  const [
    getPropertyContactByPropertyIdQuery,
    { loading, data, refetch },
  ] = useLazyQuery<
    PropertyContactByPropertyIdQuery,
    PropertyContactByPropertyIdQueryVariables
  >(getPropertyContactByPropertyId, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted() {},
  });

  // Mutation: Create Property Contact
  const [createPropertyContactMutation] = useMutation<
    CreatePropertyContactMutation,
    CreatePropertyContactMutationVariables
  >(createPropertyContact, {
    onCompleted() {
      refetch();
      setName("");
      setPhone("");
      setRole("");
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Delete Property Contact
  const [deletePropertyContactMutation] = useMutation<
    DeletePropertyContactMutation,
    DeletePropertyContactMutationVariables
  >(deletePropertyContact, {
    onCompleted() {
      refetch();
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Update Property Contact
  const [updatePropertyContactMutation] = useMutation<
    UpdatePropertyContactMutation,
    UpdatePropertyContactMutationVariables
  >(updatePropertyContact, {
    onCompleted() {
      refetch();
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    if (props.open)
      getPropertyContactByPropertyIdQuery({
        variables: {
          id: props.propertyId,
        },
      });
  }, [getPropertyContactByPropertyIdQuery, props.open, props.propertyId]);

  const contacts = data?.propertyById?.contacts || [];

  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>Tengiliðir fyrir {props.propertyName}</Modal.Header>
      <Modal.Content>
        <ListContainer>
          <List selection verticalAlign="middle">
            {contacts.length > 0 ? (
              contacts.map((contact) => {
                return (
                  <List.Item key={contact.id}>
                    <Image
                      avatar
                      width="50"
                      src="https://static.thenounproject.com/png/363639-200.png"
                    />
                    <List.Content>
                      <List.Header>{contact.name}</List.Header>
                      <List.Description>
                        <b>Símanúmer</b>: {contact.phone}
                        <br />
                        <b>Póstfang</b>: {contact.email}
                        <br />
                        <b>Hlutverk</b>: {contact.role}
                      </List.Description>
                    </List.Content>
                    <Button
                      onClick={() =>
                        setEditContact({
                          id: contact.id,
                          name: contact.name,
                          role: contact.role || "",
                          email: contact.email || "",
                          phone: contact.phone || "",
                        })
                      }
                      positive
                      floated="right"
                    >
                      <Icon name="edit"></Icon>
                      Breyta
                    </Button>
                    <Button
                      onClick={() =>
                        deletePropertyContactMutation({
                          variables: { id: contact.id },
                        })
                      }
                      negative
                      floated="right"
                    >
                      <Icon name="x"></Icon>
                      Eyða
                    </Button>
                  </List.Item>
                );
              })
            ) : (
              <EmptyState>
                Enginn tengiliður skráður fyrir húsfélagið
              </EmptyState>
            )}
          </List>
        </ListContainer>
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              onChange={(e, data) =>
                editContact
                  ? setEditContact({ ...editContact, name: data.value })
                  : setName(data.value)
              }
              fluid
              label="Nafn"
              placeholder="Nafn"
              value={editContact ? editContact.name : name}
            />
            <Form.Input
              onChange={(e, data) =>
                editContact
                  ? setEditContact({ ...editContact, role: data.value })
                  : setRole(data.value)
              }
              fluid
              label="Hlutverk"
              placeholder="Formaður"
              value={editContact ? editContact.role : role}
            />
            <Form.Input
              onChange={(e, data) =>
                editContact
                  ? setEditContact({ ...editContact, phone: data.value })
                  : setPhone(data.value)
              }
              fluid
              label="Símanúmer"
              placeholder="8581234"
              value={editContact ? editContact.phone : phone}
            />
            <Form.Input
              onChange={(e, data) =>
                editContact
                  ? setEditContact({
                      ...editContact,
                      email: data.value,
                    })
                  : setEmail(data.value)
              }
              fluid
              label="Póstfang"
              placeholder="xxxx@xxxx.is"
              value={editContact ? editContact.email : email}
            />
          </Form.Group>
          {!editContact ? (
            <Form.Button
              positive
              onClick={() => {
                createPropertyContactMutation({
                  variables: {
                    propertyContact: {
                      name: name,
                      phone: phone,
                      role: role,
                      email: email,
                      propertyId: props.propertyId,
                    },
                  },
                });
              }}
            >
              Búa til tengilið
            </Form.Button>
          ) : (
            <Form.Group>
              <Form.Button
                positive
                onClick={() => {
                  /* Update property contact and then clear editContact */
                  updatePropertyContactMutation({
                    variables: {
                      propertyContactId: editContact.id,
                      propertyContact: {
                        name: editContact.name,
                        phone: editContact.phone,
                        role: editContact.role,
                        email: editContact.email,
                        propertyId: props.propertyId,
                      },
                    },
                  }).then(() => setEditContact(undefined));
                }}
              >
                Breyta tengilið
              </Form.Button>
              <Form.Button
                negative
                onClick={() => {
                  /* clear editContact */
                  setEditContact(undefined);
                }}
              >
                Hætta við að breyta
              </Form.Button>
            </Form.Group>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} primary>
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default PropertyContactManageModal;
