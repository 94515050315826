import { gql } from "apollo-boost";

export const getStatistics = gql`
  query Statistics($fromDate: String!) {
    properties(limit: 1, page: 0) {
      totalCount
    }
    owners(limit: 1, page: 0) {
      totalCount
    }
    documentsStatistics {
      count
    }
    documentsStatistics {
      count
    }
    audits(
      limit: 10
      page: 0
      filters: { afterDate: $fromDate, eventName: "CUSTOMER/LOGIN" }
    ) {
      totalCount
      data {
        id
        eventName
        userId
        timestamp
        eventDescription
      }
    }
  }
`;
