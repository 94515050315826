export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The `Upload` scalar type represents a file upload. */
  Upload: any,
};


export enum AccessType {
  Basic = 'basic',
  Full = 'full'
}

export type AdminAuthPayload = {
   __typename?: 'AdminAuthPayload',
  token: Scalars['String'],
  user: Administrator,
};

export type Administrator = {
   __typename?: 'Administrator',
  id: Scalars['ID'],
  userName: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  role: AdminRole,
  scopes: Array<Scalars['String']>,
};

export type AdministratorCreateInput = {
  name: Scalars['String'],
  userName: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  role: AdminRole,
  password: Scalars['String'],
  password_again: Scalars['String'],
};

export type AdministratorPasswordInput = {
  password: Scalars['String'],
  password_again: Scalars['String'],
};

export type AdministratorUpdateInput = {
  name: Scalars['String'],
  userName: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  role: AdminRole,
};

export enum AdminRole {
  Superuser = 'SUPERUSER',
  Uploader = 'UPLOADER'
}

export type Audit = {
   __typename?: 'Audit',
  id: Scalars['String'],
  userId: Scalars['String'],
  affectedUserId?: Maybe<Scalars['String']>,
  eventName: Scalars['String'],
  eventDescription?: Maybe<Scalars['String']>,
  timestamp: Scalars['String'],
};

export type AuditFilterInput = {
  eventName?: Maybe<Scalars['String']>,
  afterDate?: Maybe<Scalars['String']>,
};

export type AuditResults = {
   __typename?: 'AuditResults',
  totalCount: Scalars['Int'],
  data?: Maybe<Array<Audit>>,
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}

export type Document = {
   __typename?: 'Document',
  id: Scalars['String'],
  name: Scalars['String'],
  type: Scalars['String'],
  mimetype: Scalars['String'],
  description: Scalars['String'],
  createdDate: Scalars['String'],
  modifiedDate: Scalars['String'],
  displayDate: Scalars['String'],
  properties?: Maybe<Array<PropertyDocumentAssignment>>,
};

export enum DocumentAccessType {
  Basic = 'BASIC',
  Full = 'FULL'
}

export type DocumentFilterInput = {
  name?: Maybe<Scalars['String']>,
  type?: Maybe<Array<Scalars['String']>>,
  property?: Maybe<Array<Scalars['String']>>,
};

export type DocumentForProperty = {
   __typename?: 'DocumentForProperty',
  id: Scalars['String'],
  name: Scalars['String'],
  type: Scalars['String'],
  mimetype: Scalars['String'],
  description: Scalars['String'],
  createdDate: Scalars['String'],
  modifiedDate: Scalars['String'],
  displayDate: Scalars['String'],
  accessType: DocumentAccessType,
};

export type DocumentInput = {
  name: Scalars['String'],
  type: Scalars['String'],
  description: Scalars['String'],
  displayDate: Scalars['String'],
  propertyIds?: Maybe<Array<Scalars['String']>>,
};

export type DocumentResults = {
   __typename?: 'DocumentResults',
  totalCount: Scalars['Int'],
  data?: Maybe<Array<Document>>,
};

export type DocumentStatistic = {
   __typename?: 'DocumentStatistic',
  count: Scalars['Int'],
};

export type Incident = {
   __typename?: 'Incident',
  id: Scalars['String'],
  authorId: Scalars['String'],
  author: Administrator,
  propertyId: Scalars['String'],
  property: Property,
  reporterOwnerId?: Maybe<Scalars['String']>,
  reporterOwner?: Maybe<Owner>,
  reporterOther?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  category: Scalars['String'],
  numberReference?: Maybe<Scalars['String']>,
  body: Scalars['String'],
  created: Scalars['String'],
};

export type IncidentInput = {
  authorId: Scalars['String'],
  propertyId: Scalars['String'],
  reporterOwnerId?: Maybe<Scalars['String']>,
  reporterOther?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  category: Scalars['String'],
  numberReference?: Maybe<Scalars['String']>,
  body: Scalars['String'],
};

export type IncidentsFilterInput = {
  title?: Maybe<Scalars['String']>,
  propertyId?: Maybe<Scalars['String']>,
  reporterName?: Maybe<Scalars['String']>,
};

export type IncidentsResults = {
   __typename?: 'IncidentsResults',
  totalCount: Scalars['Int'],
  data?: Maybe<Array<Incident>>,
};

export type Invoice = {
   __typename?: 'Invoice',
  invoiceNo: Scalars['String'],
  socialNumber: Scalars['String'],
  amount: Scalars['String'],
  dueDate: Scalars['String'],
  remainingAmount: Scalars['String'],
};

export type Mutation = {
   __typename?: 'Mutation',
  login: AdminAuthPayload,
  createAdminUser: Scalars['String'],
  deleteAdminUser: Scalars['Boolean'],
  updateAdminUser: Scalars['Boolean'],
  updateAdminUserPassword: Scalars['Boolean'],
  createIncident: Incident,
  deleteIncident: Scalars['Boolean'],
  uploadDocuments?: Maybe<Array<Document>>,
  uploadDocumentsV2?: Maybe<Array<Document>>,
  deleteDocument: Scalars['Boolean'],
  updateDocument: Scalars['Boolean'],
  assignDocumentToProperty: Scalars['Boolean'],
  unassignDocumentFromProperty: Scalars['Boolean'],
  createOwner: Scalars['String'],
  deleteOwnerById: Scalars['Boolean'],
  updateOwnerById: Scalars['Boolean'],
  connectOwnerToOwnerById: Scalars['Boolean'],
  disconnectOwnerFromOwnerById: Scalars['Boolean'],
  createProperty: Scalars['String'],
  updateProperty: Scalars['Boolean'],
  deletePropertyById: Scalars['String'],
  assignOwnerToProperty: Scalars['Boolean'],
  unassignOwnerFromProperty: Scalars['Boolean'],
  createPropertyContact: Scalars['String'],
  updatePropertyContact: Scalars['Boolean'],
  deletePropertyContact: Scalars['Boolean'],
  upsertPropertyInformation: PropertyInformation,
  deletePropertyInformation: Scalars['Boolean'],
};


export type MutationLoginArgs = {
  userName: Scalars['String'],
  password: Scalars['String']
};


export type MutationCreateAdminUserArgs = {
  user: AdministratorCreateInput
};


export type MutationDeleteAdminUserArgs = {
  id: Scalars['String']
};


export type MutationUpdateAdminUserArgs = {
  id: Scalars['String'],
  user: AdministratorUpdateInput
};


export type MutationUpdateAdminUserPasswordArgs = {
  id: Scalars['String'],
  password: AdministratorPasswordInput
};


export type MutationCreateIncidentArgs = {
  input?: Maybe<IncidentInput>
};


export type MutationDeleteIncidentArgs = {
  id: Scalars['String']
};


export type MutationUploadDocumentsArgs = {
  files: Array<Scalars['Upload']>,
  type: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  displayDate?: Maybe<Scalars['String']>
};


export type MutationUploadDocumentsV2Args = {
  files: Array<Scalars['Upload']>,
  type: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  displayDate?: Maybe<Scalars['String']>
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['String'],
  cascadeDelete?: Maybe<Scalars['Boolean']>
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['String'],
  document: DocumentInput
};


export type MutationAssignDocumentToPropertyArgs = {
  propertyId: Scalars['String'],
  documentId: Scalars['String'],
  assignmentNote?: Maybe<Scalars['String']>,
  accessType?: Maybe<DocumentAccessType>
};


export type MutationUnassignDocumentFromPropertyArgs = {
  propertyId: Scalars['String'],
  documentId: Scalars['String']
};


export type MutationCreateOwnerArgs = {
  owner: OwnerCreateInput
};


export type MutationDeleteOwnerByIdArgs = {
  id: Scalars['String']
};


export type MutationUpdateOwnerByIdArgs = {
  id: Scalars['String'],
  owner: OwnerUpdateInput
};


export type MutationConnectOwnerToOwnerByIdArgs = {
  ownerId: Scalars['String'],
  connectingOwnerId: Scalars['String'],
  reason?: Maybe<Scalars['String']>
};


export type MutationDisconnectOwnerFromOwnerByIdArgs = {
  ownerId: Scalars['String'],
  connectedOwnerId: Scalars['String']
};


export type MutationCreatePropertyArgs = {
  property: PropertyInput
};


export type MutationUpdatePropertyArgs = {
  property: PropertyInput,
  propertyId: Scalars['String']
};


export type MutationDeletePropertyByIdArgs = {
  id: Scalars['String']
};


export type MutationAssignOwnerToPropertyArgs = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String'],
  assignmentNote?: Maybe<Scalars['String']>,
  access?: Maybe<AccessType>
};


export type MutationUnassignOwnerFromPropertyArgs = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String']
};


export type MutationCreatePropertyContactArgs = {
  propertyContact: PropertyContactInput
};


export type MutationUpdatePropertyContactArgs = {
  propertyContact: PropertyContactInput,
  propertyContactId: Scalars['String']
};


export type MutationDeletePropertyContactArgs = {
  propertyContactId: Scalars['String']
};


export type MutationUpsertPropertyInformationArgs = {
  input: PropertyInformationInput
};


export type MutationDeletePropertyInformationArgs = {
  propertyId: Scalars['String'],
  key: Scalars['String']
};

export type Owner = {
   __typename?: 'Owner',
  userId: Scalars['ID'],
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  properties?: Maybe<Array<Maybe<Property>>>,
};

export type OwnerCreateInput = {
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  password: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
};

export type OwnerFilterInput = {
  name?: Maybe<Scalars['String']>,
  socialNumber?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  property_id?: Maybe<Scalars['String']>,
};

export type OwnerForProperty = {
   __typename?: 'OwnerForProperty',
  userId: Scalars['ID'],
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  access: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
};

export type OwnerPasswordInput = {
  password: Scalars['String'],
};

export type OwnerResults = {
   __typename?: 'OwnerResults',
  totalCount: Scalars['Int'],
  data?: Maybe<Array<Owner>>,
};

export type OwnerUpdateInput = {
  socialNumber: Scalars['String'],
  name: Scalars['String'],
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
};

export type Property = {
   __typename?: 'Property',
  id: Scalars['ID'],
  registrationId?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
  address: Scalars['String'],
  owners?: Maybe<Array<Owner>>,
  documents?: Maybe<Array<DocumentForProperty>>,
  contacts?: Maybe<Array<PropertyContact>>,
  information: Array<PropertyInformation>,
};

export type PropertyContact = {
   __typename?: 'PropertyContact',
  id: Scalars['ID'],
  name: Scalars['String'],
  role?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  created: Scalars['String'],
  modified: Scalars['String'],
  propertyId: Scalars['String'],
  propertyName: Scalars['String'],
};

export type PropertyContactInput = {
  name: Scalars['String'],
  role: Scalars['String'],
  phone?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  propertyId: Scalars['String'],
};

export type PropertyDocumentAssignment = {
   __typename?: 'PropertyDocumentAssignment',
  property: Property,
  accessType: DocumentAccessType,
  note?: Maybe<Scalars['String']>,
};

export type PropertyFilterInput = {
  name?: Maybe<Scalars['String']>,
};

export type PropertyInformation = {
   __typename?: 'PropertyInformation',
  key: Scalars['String'],
  title: Scalars['String'],
  value: Scalars['String'],
  createdAt: Scalars['String'],
  modifiedAt: Scalars['String'],
};

export type PropertyInformationInput = {
  key: Scalars['String'],
  title: Scalars['String'],
  value: Scalars['String'],
  propertyId: Scalars['String'],
};

export type PropertyInput = {
  address: Scalars['String'],
  registrationId?: Maybe<Scalars['String']>,
  externalId?: Maybe<Scalars['String']>,
};

export type PropertyResults = {
   __typename?: 'PropertyResults',
  totalCount: Scalars['Int'],
  data: Array<Property>,
};

export type Query = {
   __typename?: 'Query',
  me?: Maybe<Administrator>,
  adminUserById?: Maybe<Administrator>,
  admins?: Maybe<Array<Maybe<Administrator>>>,
  audits?: Maybe<AuditResults>,
  incidents?: Maybe<IncidentsResults>,
  incident: Incident,
  documents?: Maybe<DocumentResults>,
  documentById: Document,
  documentsStatistics: DocumentStatistic,
  invoicesByOwnerAndPropertyName: Array<Invoice>,
  ownerById?: Maybe<Owner>,
  ownersByProperty: Array<OwnerForProperty>,
  owners: OwnerResults,
  previewTokenForOwner: Scalars['String'],
  connectedOwnersByOwner: Array<Owner>,
  propertyById?: Maybe<Property>,
  properties: PropertyResults,
  propertyContacts: Array<PropertyContact>,
};


export type QueryAdminUserByIdArgs = {
  id: Scalars['String']
};


export type QueryAuditsArgs = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<AuditFilterInput>
};


export type QueryIncidentsArgs = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<IncidentsFilterInput>
};


export type QueryIncidentArgs = {
  incidentId: Scalars['String']
};


export type QueryDocumentsArgs = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<DocumentFilterInput>
};


export type QueryDocumentByIdArgs = {
  id: Scalars['String']
};


export type QueryInvoicesByOwnerAndPropertyNameArgs = {
  propertyName: Scalars['String'],
  ownerSocialNumber: Scalars['String'],
  take: Scalars['Int']
};


export type QueryOwnerByIdArgs = {
  id: Scalars['String']
};


export type QueryOwnersByPropertyArgs = {
  propertyId?: Maybe<Scalars['String']>
};


export type QueryOwnersArgs = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<OwnerFilterInput>
};


export type QueryPreviewTokenForOwnerArgs = {
  socialNumber: Scalars['String']
};


export type QueryConnectedOwnersByOwnerArgs = {
  ownerId: Scalars['String']
};


export type QueryPropertyByIdArgs = {
  id: Scalars['String']
};


export type QueryPropertiesArgs = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<PropertyFilterInput>
};


export type QueryPropertyContactsArgs = {
  role: Scalars['String']
};


export type GetIncidentsQueryVariables = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<IncidentsFilterInput>
};


export type GetIncidentsQuery = (
  { __typename?: 'Query' }
  & { incidents: Maybe<(
    { __typename?: 'IncidentsResults' }
    & Pick<IncidentsResults, 'totalCount'>
    & { data: Maybe<Array<(
      { __typename?: 'Incident' }
      & Pick<Incident, 'id' | 'authorId' | 'propertyId' | 'reporterOwnerId' | 'reporterOther' | 'title' | 'category' | 'numberReference' | 'body' | 'created'>
      & { reporterOwner: Maybe<(
        { __typename?: 'Owner' }
        & Pick<Owner, 'name'>
      )>, property: (
        { __typename?: 'Property' }
        & Pick<Property, 'address'>
      ) }
    )>> }
  )> }
);

export type GetIncidentQueryVariables = {
  id: Scalars['String']
};


export type GetIncidentQuery = (
  { __typename?: 'Query' }
  & { incident: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'id' | 'authorId' | 'propertyId' | 'reporterOwnerId' | 'reporterOther' | 'title' | 'category' | 'numberReference' | 'body' | 'created'>
    & { author: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'name'>
    ), reporterOwner: Maybe<(
      { __typename?: 'Owner' }
      & Pick<Owner, 'name'>
    )>, property: (
      { __typename?: 'Property' }
      & Pick<Property, 'address'>
    ) }
  ) }
);

export type CreateIncidentMutationVariables = {
  input?: Maybe<IncidentInput>
};


export type CreateIncidentMutation = (
  { __typename?: 'Mutation' }
  & { createIncident: (
    { __typename?: 'Incident' }
    & Pick<Incident, 'id' | 'authorId' | 'propertyId' | 'reporterOwnerId' | 'reporterOther' | 'title' | 'category' | 'numberReference' | 'body' | 'created'>
  ) }
);

export type DeleteIncidentMutationVariables = {
  deleteIncidentId: Scalars['String']
};


export type DeleteIncidentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteIncident'>
);

export type DocumentsQueryVariables = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<DocumentFilterInput>
};


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { documents: Maybe<(
    { __typename?: 'DocumentResults' }
    & Pick<DocumentResults, 'totalCount'>
    & { data: Maybe<Array<(
      { __typename?: 'Document' }
      & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'createdDate' | 'modifiedDate' | 'displayDate'>
      & { properties: Maybe<Array<(
        { __typename?: 'PropertyDocumentAssignment' }
        & Pick<PropertyDocumentAssignment, 'accessType'>
        & { property: (
          { __typename?: 'Property' }
          & Pick<Property, 'id' | 'address'>
        ) }
      )>> }
    )>> }
  )> }
);

export type DocumentByIdQueryVariables = {
  id: Scalars['String']
};


export type DocumentByIdQuery = (
  { __typename?: 'Query' }
  & { documentById: (
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'createdDate' | 'modifiedDate' | 'displayDate'>
    & { properties: Maybe<Array<(
      { __typename?: 'PropertyDocumentAssignment' }
      & Pick<PropertyDocumentAssignment, 'accessType'>
      & { property: (
        { __typename?: 'Property' }
        & Pick<Property, 'id' | 'address'>
      ) }
    )>> }
  ) }
);

export type UploadDocumentsMutationVariables = {
  files: Array<Scalars['Upload']>,
  type: Scalars['String'],
  description?: Maybe<Scalars['String']>
};


export type UploadDocumentsMutation = (
  { __typename?: 'Mutation' }
  & { uploadDocuments: Maybe<Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'displayDate'>
  )>> }
);

export type UploadDocumentsV2MutationVariables = {
  files: Array<Scalars['Upload']>,
  type: Scalars['String'],
  description?: Maybe<Scalars['String']>
};


export type UploadDocumentsV2Mutation = (
  { __typename?: 'Mutation' }
  & { uploadDocumentsV2: Maybe<Array<(
    { __typename?: 'Document' }
    & Pick<Document, 'id' | 'name' | 'type' | 'mimetype' | 'description' | 'displayDate'>
  )>> }
);

export type UpdateDocumentMutationVariables = {
  id: Scalars['String'],
  document: DocumentInput
};


export type UpdateDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocument'>
);

export type DeleteDocumentMutationVariables = {
  id: Scalars['String']
};


export type DeleteDocumentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDocument'>
);

export type AssignDocumentToPropertyMutationVariables = {
  propertyId: Scalars['String'],
  documentId: Scalars['String'],
  note?: Maybe<Scalars['String']>,
  accessType: DocumentAccessType
};


export type AssignDocumentToPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignDocumentToProperty'>
);

export type UnassignDocumentFromPropertyMutationVariables = {
  propertyId: Scalars['String'],
  documentId: Scalars['String']
};


export type UnassignDocumentFromPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignDocumentFromProperty'>
);

export type LoginMutationVariables = {
  userName: Scalars['String'],
  pass: Scalars['String']
};


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AdminAuthPayload' }
    & Pick<AdminAuthPayload, 'token'>
    & { user: (
      { __typename?: 'Administrator' }
      & Pick<Administrator, 'userName' | 'id'>
    ) }
  ) }
);

export type PropertyContactsQueryVariables = {
  role: Scalars['String']
};


export type PropertyContactsQuery = (
  { __typename?: 'Query' }
  & { propertyContacts: Array<(
    { __typename?: 'PropertyContact' }
    & Pick<PropertyContact, 'id' | 'name' | 'role' | 'phone' | 'email' | 'created' | 'modified' | 'propertyId' | 'propertyName'>
  )> }
);

export type PropertiesQueryVariables = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<PropertyFilterInput>
};


export type PropertiesQuery = (
  { __typename?: 'Query' }
  & { properties: (
    { __typename?: 'PropertyResults' }
    & Pick<PropertyResults, 'totalCount'>
    & { data: Array<(
      { __typename?: 'Property' }
      & Pick<Property, 'id' | 'address' | 'externalId' | 'registrationId'>
      & { owners: Maybe<Array<(
        { __typename?: 'Owner' }
        & Pick<Owner, 'userId'>
      )>>, information: Array<(
        { __typename?: 'PropertyInformation' }
        & Pick<PropertyInformation, 'key' | 'title' | 'value' | 'createdAt' | 'modifiedAt'>
      )> }
    )> }
  ) }
);

export type PropertyByIdQueryVariables = {
  id: Scalars['String']
};


export type PropertyByIdQuery = (
  { __typename?: 'Query' }
  & { propertyById: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'id' | 'address' | 'externalId' | 'registrationId'>
    & { information: Array<(
      { __typename?: 'PropertyInformation' }
      & Pick<PropertyInformation, 'key' | 'title' | 'value' | 'createdAt' | 'modifiedAt'>
    )> }
  )> }
);

export type PropertyContactByPropertyIdQueryVariables = {
  id: Scalars['String']
};


export type PropertyContactByPropertyIdQuery = (
  { __typename?: 'Query' }
  & { propertyById: Maybe<(
    { __typename?: 'Property' }
    & { contacts: Maybe<Array<(
      { __typename?: 'PropertyContact' }
      & Pick<PropertyContact, 'name' | 'id' | 'propertyId' | 'phone' | 'email' | 'created' | 'modified' | 'role'>
    )>> }
  )> }
);

export type CreatePropertyContactMutationVariables = {
  propertyContact: PropertyContactInput
};


export type CreatePropertyContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPropertyContact'>
);

export type DeletePropertyContactMutationVariables = {
  id: Scalars['String']
};


export type DeletePropertyContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePropertyContact'>
);

export type UpdatePropertyContactMutationVariables = {
  propertyContactId: Scalars['String'],
  propertyContact: PropertyContactInput
};


export type UpdatePropertyContactMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePropertyContact'>
);

export type CreatePropertyMutationVariables = {
  property: PropertyInput
};


export type CreatePropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProperty'>
);

export type DeletePropertyMutationVariables = {
  id: Scalars['String']
};


export type DeletePropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePropertyById'>
);

export type AssignOwnerToPropertyMutationVariables = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String'],
  access?: Maybe<AccessType>
};


export type AssignOwnerToPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'assignOwnerToProperty'>
);

export type UnassignOwnerFromPropertyMutationVariables = {
  propertyId: Scalars['String'],
  ownerId: Scalars['String']
};


export type UnassignOwnerFromPropertyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unassignOwnerFromProperty'>
);

export type UpsertPropertyInformationMutationVariables = {
  key: Scalars['String'],
  value: Scalars['String'],
  title: Scalars['String'],
  propertyId: Scalars['String']
};


export type UpsertPropertyInformationMutation = (
  { __typename?: 'Mutation' }
  & { upsertPropertyInformation: (
    { __typename?: 'PropertyInformation' }
    & Pick<PropertyInformation, 'key' | 'title' | 'value' | 'createdAt' | 'modifiedAt'>
  ) }
);

export type DeletePropertyInformationMutationVariables = {
  key: Scalars['String'],
  propertyId: Scalars['String']
};


export type DeletePropertyInformationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePropertyInformation'>
);

export type StatisticsQueryVariables = {
  fromDate: Scalars['String']
};


export type StatisticsQuery = (
  { __typename?: 'Query' }
  & { properties: (
    { __typename?: 'PropertyResults' }
    & Pick<PropertyResults, 'totalCount'>
  ), owners: (
    { __typename?: 'OwnerResults' }
    & Pick<OwnerResults, 'totalCount'>
  ), documentsStatistics: (
    { __typename?: 'DocumentStatistic' }
    & Pick<DocumentStatistic, 'count'>
  ), audits: Maybe<(
    { __typename?: 'AuditResults' }
    & Pick<AuditResults, 'totalCount'>
    & { data: Maybe<Array<(
      { __typename?: 'Audit' }
      & Pick<Audit, 'id' | 'eventName' | 'userId' | 'timestamp' | 'eventDescription'>
    )>> }
  )> }
);

export type OwnersQueryVariables = {
  limit: Scalars['Int'],
  page: Scalars['Int'],
  filters?: Maybe<OwnerFilterInput>
};


export type OwnersQuery = (
  { __typename?: 'Query' }
  & { owners: (
    { __typename?: 'OwnerResults' }
    & Pick<OwnerResults, 'totalCount'>
    & { data: Maybe<Array<(
      { __typename?: 'Owner' }
      & Pick<Owner, 'userId' | 'email' | 'phone' | 'name' | 'socialNumber'>
      & { properties: Maybe<Array<Maybe<(
        { __typename?: 'Property' }
        & Pick<Property, 'id' | 'address'>
      )>>> }
    )>> }
  ) }
);

export type OwnersByPropertyQueryVariables = {
  propertyId: Scalars['String']
};


export type OwnersByPropertyQuery = (
  { __typename?: 'Query' }
  & { ownersByProperty: Array<(
    { __typename?: 'OwnerForProperty' }
    & Pick<OwnerForProperty, 'userId' | 'email' | 'name' | 'phone' | 'socialNumber' | 'access'>
  )> }
);

export type OwnerByIdQueryVariables = {
  id: Scalars['String']
};


export type OwnerByIdQuery = (
  { __typename?: 'Query' }
  & { ownerById: Maybe<(
    { __typename?: 'Owner' }
    & Pick<Owner, 'userId' | 'email' | 'phone' | 'name' | 'socialNumber'>
  )> }
);

export type CreateOwnerMutationVariables = {
  owner: OwnerCreateInput
};


export type CreateOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOwner'>
);

export type DeleteOwnerMutationVariables = {
  ownerId: Scalars['String']
};


export type DeleteOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteOwnerById'>
);

export type UpdateOwnerMutationVariables = {
  ownerId: Scalars['String'],
  owner: OwnerUpdateInput
};


export type UpdateOwnerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOwnerById'>
);

export type AdminUserByIdQueryVariables = {
  id: Scalars['String']
};


export type AdminUserByIdQuery = (
  { __typename?: 'Query' }
  & { adminUserById: Maybe<(
    { __typename?: 'Administrator' }
    & Pick<Administrator, 'id' | 'userName' | 'email' | 'name' | 'role'>
  )> }
);

export type UpdateAdminUserByIdMutationVariables = {
  id: Scalars['String'],
  user: AdministratorUpdateInput
};


export type UpdateAdminUserByIdMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAdminUser'>
);

export type UpdateAdminUserPasswordMutationVariables = {
  id: Scalars['String'],
  password: Scalars['String'],
  password_again: Scalars['String']
};


export type UpdateAdminUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateAdminUserPassword'>
);

export type CreateAdminUserMutationVariables = {
  user: AdministratorCreateInput
};


export type CreateAdminUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createAdminUser'>
);

export type DeleteAdminUserMutationVariables = {
  id: Scalars['String']
};


export type DeleteAdminUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAdminUser'>
);

export type GetAdminsQueryVariables = {};


export type GetAdminsQuery = (
  { __typename?: 'Query' }
  & { admins: Maybe<Array<Maybe<(
    { __typename?: 'Administrator' }
    & Pick<Administrator, 'id' | 'userName' | 'name' | 'email' | 'role'>
  )>>> }
);

export type GetPreviewTokenQueryVariables = {
  socialNumber: Scalars['String']
};


export type GetPreviewTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'previewTokenForOwner'>
);
