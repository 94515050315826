import * as React from "react";
import { Menu, Dropdown, Icon, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearSession } from "../../store/system";
import * as AuthService from "../../services/AuthService";
import AdminProfileModal from "../modals/AdminProfileModal";
import { AppState } from "../../store";
import AdminChangePasswordModal from "../modals/AdminChangePasswordModal";
import AdminCreateModal from "../modals/AdminCreateModal";
import { Media } from "../../..";

const Header = () => {
  const [profileEditOpen, setprofileEditOpen] = React.useState<boolean>();
  const [adminCreateOpen, setAdminCreateOpen] = React.useState<boolean>();
  const [adminChangePassOpen, setAdminChangePassOpen] = React.useState<
    boolean
  >();
  const { loggedInUserId } = useSelector((state: AppState) => state.system);

  const dispatch = useDispatch();
  return (
    <Menu
      borderless
      fixed="top"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #ddd",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
      }}
    >
      <AdminProfileModal
        open={profileEditOpen || false}
        adminUserId={loggedInUserId}
        close={() => {
          setprofileEditOpen(false);
        }}
        onUpdated={() => {}}
      />

      <AdminCreateModal
        open={adminCreateOpen || false}
        close={() => {
          setAdminCreateOpen(false);
        }}
        onUpdated={() => {}}
      />
      <AdminChangePasswordModal
        open={adminChangePassOpen || false}
        adminUserId={loggedInUserId}
        adminUserName="innskráðan notanda"
        close={() => {
          setAdminChangePassOpen(false);
        }}
        onUpdated={() => {}}
      />

      <Menu.Item header>EIGNAREKSTUR/STJÓRNUN</Menu.Item>
      <Container as={Media} at="mobile">
        <Menu.Menu position="right">
          <Dropdown icon="sitemap" text="Síður " pointing className="link item">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/">
                <Icon name="chart bar outline" /> Mælaborð
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/properties">
                <Icon name="building outline" /> Húsfélög
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/owners">
                <Icon name="address book outline" /> Íbúar
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/documents">
                <Icon name="folder outline" /> Skjöl
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/incidents">
                <Icon name="file outline" /> Atvikaskrá
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/reports">
                <Icon name="file outline" /> Skýrslur
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Container>
      <Container
        as={Media}
        greaterThanOrEqual="tablet"
        style={{ maxWidth: 800 }}
      >
        <>
          <Menu.Item as={Link} to="/">
            <Icon name="chart bar outline" /> Mælaborð
          </Menu.Item>
          <Menu.Item as={Link} to="/properties">
            <Icon name="building outline" /> Húsfélög
          </Menu.Item>
          <Menu.Item as={Link} to="/owners">
            <Icon name="address book outline" /> Íbúar
          </Menu.Item>
          <Menu.Item as={Link} to="/documents">
            <Icon name="folder outline" /> Skjöl
          </Menu.Item>
          <Menu.Item as={Link} to="/incidents">
            <Icon name="file outline" /> Atvikaskrá
          </Menu.Item>
          <Menu.Item as={Link} to="/reports">
            <Icon name="file outline" /> Skýrslur
          </Menu.Item>
        </>
      </Container>

      <Menu.Menu position="right">
        <Dropdown
          icon="configure"
          text="Aðgerðir "
          pointing
          className="link item"
        >
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                dispatch(clearSession());
                AuthService.logout();
              }}
            >
              <Icon name="log out" /> Skrá út
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Stillingar</Dropdown.Header>
            <Dropdown.Item
              onClick={() => {
                setprofileEditOpen(true);
              }}
            >
              <Icon name="user" />
              Prófíll
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setAdminChangePassOpen(true);
              }}
            >
              Breyta lykilorði
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Stjórnandi</Dropdown.Header>
            <Dropdown.Item
              onClick={() => {
                setAdminCreateOpen(true);
              }}
            >
              <Icon name="user plus" /> Búa til stjórnanda
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/admins">
              <Icon name="user doctor" /> Sýsla með stjórnendur
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default Header;
