import * as React from "react";
import { Modal, Loader, Button, List, Icon, Dropdown } from "semantic-ui-react";
import {
  OwnersByPropertyQuery,
  OwnersByPropertyQueryVariables,
  UnassignOwnerFromPropertyMutation,
  UnassignOwnerFromPropertyMutationVariables,
  AssignOwnerToPropertyMutation,
  AssignOwnerToPropertyMutationVariables,
  AccessType,
} from "../../../types";
import { useLazyQuery, useMutation } from "react-apollo";
import { toast } from "react-toastify";
import { getOwnersByProperty } from "../../queries/userQueries";
import OwnerDetailModal from "./OwnerDetailModal";
import {
  unassignOwnerFromProperty,
  assignOwnerToProperty,
} from "../../queries/propertyQueries";

interface OwnersInPropertyModalProps {
  open: boolean;
  title: string;
  propertyId: string;
  close: () => void;
  onUpdate: () => void;
}

/*
 * OwnersInPropertyModal shows owners for some property
 */
export const OwnersInPropertyModal = (props: OwnersInPropertyModalProps) => {
  /* Queries */

  // Query: Get Owner By Id.
  const [getOwnersByPropertyQuery, { loading, data, refetch }] = useLazyQuery<
    OwnersByPropertyQuery,
    OwnersByPropertyQueryVariables
  >(getOwnersByProperty, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted() {},
  });

  // Mutation: Unassign owner from property
  const [unassignOwnerFromPropertyMutation] = useMutation<
    UnassignOwnerFromPropertyMutation,
    UnassignOwnerFromPropertyMutationVariables
  >(unassignOwnerFromProperty, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Leyfi tekið af íbúa");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: assign owner to property
  const [assignOwnerToPropertyMutation] = useMutation<
    AssignOwnerToPropertyMutation,
    AssignOwnerToPropertyMutationVariables
  >(assignOwnerToProperty, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Leyfi uppfært fyrir íbúa");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    getOwnersByPropertyQuery({
      variables: {
        propertyId: props.propertyId,
      },
    });
  }, [props.propertyId, getOwnersByPropertyQuery]);

  /* Helper functions */

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <List divided verticalAlign="middle">
          {(data?.ownersByProperty || []).length > 0 ? (
            data?.ownersByProperty.map((owner) => (
              <List.Item key={owner.userId}>
                <List.Content floated="right">
                  <Dropdown
                    selection
                    value={owner.access}
                    options={[
                      {
                        key: "full",
                        text: "Full Réttindi",
                        value: "full",
                      },
                      {
                        key: "basic",
                        text: "Venjuleg Réttindi",
                        value: "basic",
                      },
                    ]}
                    onChange={(_, { value }) => {
                      value &&
                        assignOwnerToPropertyMutation({
                          variables: {
                            propertyId: props.propertyId,
                            ownerId: owner.userId,
                            access: value as AccessType,
                          },
                        }).then(() => refetch());
                    }}
                  />
                  <Button
                    size="small"
                    negative
                    onClick={() => {
                      unassignOwnerFromPropertyMutation({
                        variables: {
                          ownerId: owner.userId,
                          propertyId: props.propertyId,
                        },
                      }).then(() => refetch());
                    }}
                  >
                    Taka úr húsfélagi
                  </Button>
                </List.Content>
                <Icon size="large" avatar name="table" />
                <List.Content>{`${owner.name} (${owner.socialNumber})`}</List.Content>
              </List.Item>
            ))
          ) : (
            <div>Enginn hefur aðgang að þessu húsfélagi</div>
          )}
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.close();
            props.onUpdate();
          }}
        >
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OwnerDetailModal;
