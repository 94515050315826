import * as React from "react";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import {
  CreateOwnerMutation,
  CreateOwnerMutationVariables,
} from "../../../types";
import { useMutation } from "react-apollo";
import { createOwner } from "../../queries/userQueries";
import { toast } from "react-toastify";
import styled from "styled-components";

interface OwnerCreateModalProps {
  open: boolean;
  close: () => void;
  onUpdate: () => void;
}

const FieldNoteMessage = styled.p`
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
`;

export const OwnerCreateModal = (props: OwnerCreateModalProps) => {
  // Local state to keep track of input changes
  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [phone, setPhone] = React.useState<string>();
  const [socialNumber, setSocialNumber] = React.useState<string>();

  /* Mutations */

  // Mutaton: Create Owners
  const [createOwnerMutation, createOwnerData] = useMutation<
    CreateOwnerMutation,
    CreateOwnerMutationVariables
  >(createOwner, {
    onCompleted(data) {
      props.close();
      toast.success("Aðgerð heppnaðist: Eigandi búinn til.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  const isValid = () => {
    return (
      name &&
      name !== "" &&
      email &&
      email !== "" &&
      socialNumber &&
      socialNumber !== ""
    );
  };

  return (
    <Modal open={props.open}>
      <Loader active={createOwnerData.loading} />
      <Modal.Header>Búa til íbúa</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Nafn</label>
            <input
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          </Form.Field>
          <Form.Field>
            <label>Kennitala</label>
            <input
              onChange={(e) => setSocialNumber(e.target.value)}
              placeholder="Kennitala"
            />
          </Form.Field>
          <Form.Field>
            <label>Póstfang</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Póstfang"
            />
          </Form.Field>
          <Form.Field>
            <label>Sími</label>
            <FieldNoteMessage>
              (Þessi reitur er yfirskrifaður ef íbúi innskráir sig með rafrænum
              skilríkjum)
            </FieldNoteMessage>
            <input
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Símanúmer"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            createOwnerMutation({
              variables: {
                owner: {
                  name: name || "",
                  socialNumber: socialNumber || "",
                  email: email || "",
                  password: "",
                  phone: phone || null,
                },
              },
            }).then(() => {
              props.onUpdate();
            });
          }}
          disabled={!isValid()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Búa til"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default OwnerCreateModal;
