import * as React from "react";
import { useQuery, useMutation } from "react-apollo";

import NavHeader from "../layout/Header";
import Page from "../layout/Page";
import {
  GetAdminsQuery,
  GetAdminsQueryVariables,
  DeleteAdminUserMutation,
  DeleteAdminUserMutationVariables,
} from "../../../types";
import {
  Button,
  Table,
  Icon,
  Header,
  Container,
  Dropdown,
  Confirm,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import Loading from "../layout/Loading";
import ErrorMessage from "../layout/ErrorMessage";
import { getAdmins, deleteAdminUser } from "../../queries/userQueries";
import AdminCreateModal from "../modals/AdminCreateModal";
import AdminProfileModal from "../modals/AdminProfileModal";
import { getHumanReadableAdminRoleType } from "../../utils/document";
import { AdminChangePasswordModal } from "../modals/AdminChangePasswordModal";

export const AdminsPage = () => {
  // Local state to keep track of modals
  const [createModalOpen, setCreateModalOpen] = React.useState<boolean>();
  const [editModalOpen, setEditModalOpen] = React.useState<boolean>();
  const [passwordModalOpen, setPasswordModalOpen] = React.useState<boolean>();
  const [selectedAdminIndex, setSelectedAdminIndex] = React.useState<number>();
  const [deleteConfirm, setDeleteConfirm] = React.useState<number | undefined>(
    undefined
  );

  // Query: Get Properties
  const { loading, error, data, refetch } = useQuery<
    GetAdminsQuery,
    GetAdminsQueryVariables
  >(getAdmins, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    context: {
      debounceKey: "4",
      debounceTimeout: 1000,
    },
  });

  // Mutation: Delete Admin User
  const [deleteAdminUserMutation] = useMutation<
    DeleteAdminUserMutation,
    DeleteAdminUserMutationVariables
  >(deleteAdminUser, {
    onCompleted() {
      toast.success("Aðgerð heppnaðist: Stjórnanda eytt.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  let admins = data?.admins || [];
  let selectedUser =
    selectedAdminIndex !== undefined ? admins[selectedAdminIndex] : undefined;

  return (
    <>
      <NavHeader />
      <Page>
        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Stjórnendur á skrá</Header>

          <AdminCreateModal
            open={createModalOpen || false}
            close={() => {
              setCreateModalOpen(false);
            }}
            onUpdated={() => {
              refetch();
            }}
          />
          <AdminChangePasswordModal
            open={passwordModalOpen || false}
            adminUserId={selectedUser?.id || ""}
            adminUserName={selectedUser?.name || ""}
            close={() => {
              setPasswordModalOpen(false);
            }}
            onUpdated={() => {
              refetch();
            }}
          />
          {selectedUser !== undefined && (
            <AdminProfileModal
              open={editModalOpen || false}
              adminUserId={selectedUser?.id || ""}
              close={() => {
                setEditModalOpen(false);
              }}
              onUpdated={() => {}}
            />
          )}

          {error ? (
            <ErrorMessage />
          ) : (
            <>
              <Table padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Einkenni</Table.HeaderCell>
                    <Table.HeaderCell>Nafn</Table.HeaderCell>
                    <Table.HeaderCell>Notendanafn</Table.HeaderCell>
                    <Table.HeaderCell>Póstfang</Table.HeaderCell>
                    <Table.HeaderCell>Aðgangsheimild</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {loading ? (
                    <Loading />
                  ) : admins.length > 0 ? (
                    admins.map((admin, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{admin?.id}</Table.Cell>
                        <Table.Cell>{admin?.name}</Table.Cell>
                        <Table.Cell>{admin?.userName}</Table.Cell>
                        <Table.Cell>{admin?.email}</Table.Cell>
                        <Table.Cell>
                          {getHumanReadableAdminRoleType(admin?.role)}
                        </Table.Cell>
                        <Table.Cell>
                          <Confirm
                            open={deleteConfirm === index}
                            onCancel={() => setDeleteConfirm(undefined)}
                            cancelButton="Nei"
                            confirmButton="Já"
                            content="Ertu viss?"
                            size="tiny"
                            onConfirm={() => {
                              deleteAdminUserMutation({
                                variables: {
                                  id: admin?.id || "",
                                },
                              }).then(() => {
                                setDeleteConfirm(undefined);
                                refetch();
                              });
                            }}
                          />
                          <Dropdown text="Aðgerðir">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon="delete"
                                text="Eyða stjórnanda"
                                onClick={() => setDeleteConfirm(index)}
                              />
                              <Dropdown.Item
                                icon="edit outline"
                                text="Breyta stjórnanda"
                                onClick={() => {
                                  setSelectedAdminIndex(index);
                                  setEditModalOpen(true);
                                }}
                              />
                              <Dropdown.Item
                                icon="key"
                                text="Breyta lykilorði"
                                onClick={() => {
                                  setSelectedAdminIndex(index);
                                  setPasswordModalOpen(true);
                                }}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row>Engir stjórendur fundust</Table.Row>
                  )}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell
                      colSpan="4"
                      textAlign="left"
                    ></Table.HeaderCell>
                    <Table.HeaderCell colSpan="2">
                      <Button.Group floated="right">
                        <Button
                          icon
                          labelPosition="left"
                          primary
                          size="small"
                          onClick={() => setCreateModalOpen(true)}
                        >
                          <Icon name="user" /> Búa til stjóranda
                        </Button>
                        <Button
                          icon
                          floated="right"
                          size="small"
                          onClick={() => refetch()}
                        >
                          <Icon name="refresh" /> Endursækja
                        </Button>
                      </Button.Group>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </>
          )}
        </Container>
      </Page>
    </>
  );
};

export default AdminsPage;
