import * as React from "react";
import { Modal, Loader, Button, Form, Search } from "semantic-ui-react";
import { useLazyQuery, useMutation } from "react-apollo";
import { toast } from "react-toastify";
import {
  AdminUserByIdQuery,
  AdminUserByIdQueryVariables,
  CreateIncidentMutation,
  CreateIncidentMutationVariables,
  OwnersQuery,
  OwnersQueryVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
} from "../../../types";
import { createIncident } from "../../queries/crmQueries";
import { getAdminUserById, getOwners } from "../../queries/userQueries";
import useDebounce from "../utils/debounce";
import { useEffect } from "react";
import { getProperties } from "../../queries/propertyQueries";
import { useSelector } from "react-redux";
import { AppState } from "../../store";

interface AdminCreateModalProps {
  open: boolean;
  close: () => void;
  onUpdated: () => void;
}

export const CreateIncidentModal = (props: AdminCreateModalProps) => {
  // Local state to keep track of input changes
  const [authorId, setAuthorId] = React.useState<string>("");
  const [propertyId, setPropertyId] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [reporterOther, setReporterOther] = React.useState<string>();
  const [reporterOwnerId, setReporterOwnerId] = React.useState<string>();
  const [category, setCategory] = React.useState<string>("");
  const [numberReference, setNumberReference] = React.useState<string>();
  const [body, setBody] = React.useState<string>("");
  // Local state for searching
  const [ownersName, setOwnersName] = React.useState<string>();
  const debouncedOwnersName = useDebounce(ownersName || "", 500);
  const [propertyName, setPropertyName] = React.useState<string>();
  const debouncedPropertyName = useDebounce(propertyName || "", 500);

  // Global state
  const { loggedInUserId } = useSelector((state: AppState) => state.system);

  /* Queries */
  const [getUserInfo, { data: loggedInUserData }] = useLazyQuery<
    AdminUserByIdQuery,
    AdminUserByIdQueryVariables
  >(getAdminUserById, {
    variables: {
      id: loggedInUserId,
    },
  });

  const [
    searchOwners,
    { loading: searchOwnersLoading, data: searchOwnersData },
  ] = useLazyQuery<OwnersQuery, OwnersQueryVariables>(getOwners, {
    variables: {
      limit: 10,
      page: 0,
      filters: {
        name: debouncedOwnersName,
      },
    },
  });

  const [
    searchProperties,
    { loading: searchPropertiesLoading, data: searchPropertiesData },
  ] = useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(getProperties, {
    variables: {
      limit: 10,
      page: 0,
      filters: {
        name: debouncedPropertyName,
      },
    },
  });

  useEffect(() => {
    loggedInUserData && setAuthorId(loggedInUserData.adminUserById?.id || "");
  }, [loggedInUserData]);

  // Get User Info on open
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  // Search owners when changing search
  useEffect(() => {
    searchOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOwnersName]);

  // Search owners when changing search
  useEffect(() => {
    searchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPropertyName]);

  /* Mutations */

  // Mutaton: Create Admin
  const [createIncidentMutation, createIncidentMutationData] = useMutation<
    CreateIncidentMutation,
    CreateIncidentMutationVariables
  >(createIncident, {
    onCompleted(data) {
      props.close();
      toast.success("Aðgerð heppnaðist: Atvik búið til.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  const isValid = () => {
    return (
      authorId &&
      title &&
      propertyId &&
      title &&
      category &&
      body &&
      (reporterOther || reporterOwnerId)
    );
  };

  return (
    <Modal open={props.open}>
      <Loader active={createIncidentMutationData.loading} />
      <Modal.Header>Búa til atvik</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Author</label>
            <input
              value={`${loggedInUserData?.adminUserById?.name} (${authorId})`}
              disabled={true}
              onChange={(e) => setAuthorId(e.target.value)}
              placeholder="Höfundur"
            />
          </Form.Field>
          <Form.Field>
            <label>Húsfélag</label>
            <Search
              loading={searchPropertiesLoading}
              onResultSelect={(e, data) => {
                setPropertyName(data.result.title || "");
                setPropertyId(data.result.value || "");
              }}
              onSearchChange={(p, d) => setPropertyName(d.value || "")}
              results={(searchPropertiesData?.properties?.data || []).map(
                (property) => {
                  return {
                    description: property.address,
                    title: property.address,
                    value: property.id,
                  };
                }
              )}
              value={propertyName}
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              fluid
              label="Flokkur"
              value={category}
              options={[
                {
                  key: "Símtal",
                  text: "Símtal",
                  name: "Símtal",
                  value: "Símtal",
                },
                {
                  key: "Tölvupóstur",
                  text: "Tölvupóstur",
                  name: "Tölvupóstur",
                  value: "Tölvupóstur",
                },
                {
                  key: "Samtal",
                  text: "Samtal",
                  name: "Samtal",
                  value: "Samtal",
                },
                {
                  key: "Jira Beiðni",
                  text: "Jira Beiðni",
                  name: "Jira Beiðni",
                  value: "Jira Beiðni",
                },
                {
                  key: "Bókhald",
                  text: "Bókhald",
                  name: "Bókhald",
                  value: "Bókhald",
                },
                {
                  key: "Framkvæmdir",
                  text: "Framkvæmdir",
                  name: "Framkvæmdir",
                  value: "Framkvæmdir",
                },
              ]}
              onChange={(_, { value }) => setCategory(value?.toString() || "")}
              placeholder="Flokkur"
            />
          </Form.Field>
          <Form.Field>
            <label>Titill</label>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Titill"
            />
          </Form.Field>
          <Form.Field>
            <label>Skýring</label>
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Skýring"
            />
          </Form.Field>
          <Form.Field>
            <label>Málsnúmer</label>
            <input
              value={numberReference}
              onChange={(e) => setNumberReference(e.target.value)}
              placeholder="Málsnúmer (Jira)"
            />
          </Form.Field>

          <Form.Field>
            <label>Tengiliður Atviks (Íbúi)</label>
            <Search
              loading={searchOwnersLoading}
              onResultSelect={(e, data) => {
                setReporterOwnerId(data.result.value || "");
                setOwnersName(data.result.description);
              }}
              onSearchChange={(p, d) => setOwnersName(d.value || "")}
              results={(searchOwnersData?.owners?.data || []).map((owner) => {
                return {
                  description: owner.name,
                  title: owner.name,
                  value: owner.userId,
                };
              })}
              value={ownersName}
            />
          </Form.Field>
          <Form.Field>
            <label>Annar Tengiliður Atviks</label>
            <input
              value={reporterOther || ""}
              onChange={(e) => setReporterOther(e.currentTarget.value)}
              placeholder="Tengiliður Atviks (Annar)"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            // Create incident
            createIncidentMutation({
              variables: {
                input: {
                  authorId: authorId || loggedInUserId,
                  propertyId,
                  title,
                  reporterOther,
                  reporterOwnerId,
                  category,
                  numberReference,
                  body,
                },
              },
            }).then(() => {
              // Close when succesfully updated.
              props.close();
              // Trigger update event
              props.onUpdated();
            });
          }}
          disabled={!isValid()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Búa til atvik"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreateIncidentModal;
