import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, Grid, Header, Segment, Loader } from "semantic-ui-react";
import { AppState } from "../../store";
import { updateSession } from "../../store/system";
import { LoginQuery } from "../../queries/loginQueries";
import * as AuthService from "../../services/AuthService";
import { useMutation } from "react-apollo";
import { LoginMutation, LoginMutationVariables } from "../../../types";
import { Redirect } from "react-router";

interface IProps {
  loggedIn: boolean;
  increment: number;
}

export const LoginPage = (props: IProps) => {
  const { loggedIn } = useSelector((state: AppState) => state.system);
  const dispatch = useDispatch();

  const [error, setError] = React.useState<string>();
  const [loginInput, setLogin] = React.useState<string>();
  const [passInput, setPass] = React.useState<string>();
  const [login, { loading }] = useMutation<
    LoginMutation,
    LoginMutationVariables
  >(LoginQuery, {
    onCompleted(data) {
      AuthService.setToken(data.login.token);
      dispatch(
        updateSession({ loggedIn: true, loggedInUserId: data.login.user.id })
      );
    },
    onError(...error) {
      setError(error.map(x => x.message)[0]);
    }
  });

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" textAlign="center">
          Innskráning í stjórnun
        </Header>
        <Form size="large">
          <Segment stacked>
            <Loader active={loading} />
            <Form.Input
              key="password_new"
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Notendanafn"
              onChange={e => setLogin(e.target.value)}
              error={error !== undefined}
            />
            <Form.Input
              key="password_new2"
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Lykilorð"
              type="password"
              onChange={e => setPass(e.target.value)}
              error={error}
            />

            <Button
              fluid
              size="large"
              onClick={() =>
                login({
                  variables: {
                    userName: loginInput || "",
                    pass: passInput || ""
                  }
                })
              }
            >
              Skrá inn.
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default LoginPage;
