import { Dimmer, Loader } from "semantic-ui-react";
import React from "react";
import styled from "styled-components";

const LoadingWrapper = styled.div`
  height: 650px;
`;

export const Loading = () => {
  return (
    <LoadingWrapper>
      <Dimmer active inverted>
        <Loader inverted>Hleð gögnum</Loader>
      </Dimmer>
    </LoadingWrapper>
  );
};

export default Loading;
