import { gql } from "apollo-boost";
export const getProperties = gql`
  query Properties($limit: Int!, $page: Int!, $filters: PropertyFilterInput) {
    properties(limit: $limit, page: $page, filters: $filters) {
      data {
        id
        address
        externalId
        registrationId
        owners {
          userId
        }
        information {
          key
          title
          value
          createdAt
          modifiedAt
        }
      }
      totalCount
    }
  }
`;

export const getPropertyById = gql`
  query PropertyById($id: String!) {
    propertyById(id: $id) {
      id
      address
      externalId
      registrationId
      information {
        key
        title
        value
        createdAt
        modifiedAt
      }
    }
  }
`;

export const getPropertyContactByPropertyId = gql`
  query PropertyContactByPropertyID($id: String!) {
    propertyById(id: $id) {
      contacts {
        name
        id
        propertyId
        phone
        email
        created
        modified
        role
      }
    }
  }
`;

export const createPropertyContact = gql`
  mutation CreatePropertyContact($propertyContact: PropertyContactInput!) {
    createPropertyContact(propertyContact: $propertyContact)
  }
`;

export const deletePropertyContact = gql`
  mutation DeletePropertyContact($id: String!) {
    deletePropertyContact(propertyContactId: $id)
  }
`;

export const updatePropertyContact = gql`
  mutation UpdatePropertyContact(
    $propertyContactId: String!
    $propertyContact: PropertyContactInput!
  ) {
    updatePropertyContact(
      propertyContactId: $propertyContactId
      propertyContact: $propertyContact
    )
  }
`;

export const createProperty = gql`
  mutation CreateProperty($property: PropertyInput!) {
    createProperty(property: $property)
  }
`;

export const deleteProperty = gql`
  mutation DeleteProperty($id: String!) {
    deletePropertyById(id: $id)
  }
`;

export const assignOwnerToProperty = gql`
  mutation AssignOwnerToProperty(
    $propertyId: String!
    $ownerId: String!
    $access: AccessType
  ) {
    assignOwnerToProperty(
      ownerId: $ownerId
      propertyId: $propertyId
      access: $access
    )
  }
`;

export const unassignOwnerFromProperty = gql`
  mutation UnassignOwnerFromProperty($propertyId: String!, $ownerId: String!) {
    unassignOwnerFromProperty(ownerId: $ownerId, propertyId: $propertyId)
  }
`;

export const upsertPropertyInformation = gql`
  mutation UpsertPropertyInformation(
    $key: String!
    $value: String!
    $title: String!
    $propertyId: String!
  ) {
    upsertPropertyInformation(
      input: {
        key: $key
        value: $value
        title: $title
        propertyId: $propertyId
      }
    ) {
      key
      title
      value
      createdAt
      modifiedAt
    }
  }
`;

export const deletePropertyInformation = gql`
  mutation DeletePropertyInformation($key: String!, $propertyId: String!) {
    deletePropertyInformation(key: $key, propertyId: $propertyId)
  }
`;
