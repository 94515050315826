import * as React from "react";
import { Modal, Button } from "semantic-ui-react";
import {
  OwnersByPropertyQuery,
  OwnersByPropertyQueryVariables,
} from "../../../types";
import { useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { getOwnersByProperty } from "../../queries/userQueries";
import {
  exportOwnersByPropertyToCSV,
  saveAsBlob,
} from "../../utils/export/ownerExport";

interface OwnersInPropertyExportModalProps {
  open: boolean;
  title: string;
  propertyId: string;
  close: () => void;
}

/*
 * OwnersInPropertyExportModal creates an excel sheet for exporting information about the owners.
 */
export const OwnersInPropertyExportModal = (
  props: OwnersInPropertyExportModalProps
) => {
  /* Queries */

  // Query: Get Owner by property
  const [getOwnersByPropertyQuery, { data }] = useLazyQuery<
    OwnersByPropertyQuery,
    OwnersByPropertyQueryVariables
  >(getOwnersByProperty, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted() {},
  });

  // Effects to fetch owners by property
  React.useEffect(() => {
    getOwnersByPropertyQuery({
      variables: {
        propertyId: props.propertyId,
      },
    });
  }, [props.propertyId, getOwnersByPropertyQuery]);

  // Generate and save as when data changes.
  // Only generate Excel workbook if not empty.
  React.useEffect(() => {
    const owners = data?.ownersByProperty || [];
    props.propertyId &&
      owners.length > 0 &&
      saveAsBlob(
        exportOwnersByPropertyToCSV(data?.ownersByProperty || []),
        "Resident Export"
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Render
  return (
    <Modal size="tiny" open={props.open}>
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <b>Augnablik...</b> <br /> <br />
        Er að búa til XLSX skjal sem inniheldur alla íbúa í húsfélaginu.
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            props.close();
          }}
        >
          Loka
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default OwnersInPropertyExportModal;
