import React from "react";
import { Switch, Route } from "react-router";
import { hot } from "react-hot-loader";

import "./App.css";
import PrivateRoute from "./components/routes/PrivateRoute";
import LoginPage from "./components/pages/LoginPage";
import DashPage from "./components/pages/DashPage";
import OwnersPage from "./components/pages/OwnersPage";
import PropertiesPage from "./components/pages/PropertiesPage";
import DocumentsPage from "./components/pages/DocumentsPage";
import AdminsPage from "./components/pages/AdminsPage";
import IncidentsPage from "./components/pages/IncidentPage";
import { PropertyContactsPage } from "./components/pages/reports/PropertyContacts";
import ReportsPage from "./components/pages/ReportsPage";

export const App = hot(module)(() => (
  <Switch>
    <Route path="/login" component={LoginPage} />
    <PrivateRoute path="/" exact component={DashPage} />
    <PrivateRoute path="/owners" exact component={OwnersPage} />
    <PrivateRoute path="/properties" exact component={PropertiesPage} />
    <PrivateRoute path="/documents" exact component={DocumentsPage} />
    <PrivateRoute path="/admins" exact component={AdminsPage} />
    <PrivateRoute path="/incidents" exact component={IncidentsPage} />
    <PrivateRoute path="/reports" exact component={ReportsPage} />
    <PrivateRoute
      path="/reports/contacts"
      exact
      component={PropertyContactsPage}
    />
    <Route component={() => <div>Not Found</div>} />
  </Switch>
));

export default App;
