import * as React from "react";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import {
  OwnerByIdQuery,
  OwnerByIdQueryVariables,
  UpdateOwnerMutation,
  UpdateOwnerMutationVariables,
} from "../../../types";
import { useMutation, useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import { getOwnerById, updateOwner } from "../../queries/userQueries";
import styled from "styled-components";

interface OwnerDetailModalProps {
  open: boolean;
  ownerId?: string;
  close: () => void;
  onUpdated: () => void;
}

const FieldNoteMessage = styled.p`
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
`;

/*
 * OwnerDetailModal shows detailed information about an owner,
 * and allows admins to update information about them.
 */
export const OwnerDetailModal = (props: OwnerDetailModalProps) => {
  // Local state to keep track of changes.
  const [id, setId] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [phone, setPhone] = React.useState<string>();
  const [socialNumber, setSocialNumber] = React.useState<string>();

  /* Queries */

  // Query: Get Owner By Id.
  const [getOwnerByIdQuery, { loading, data }] = useLazyQuery<
    OwnerByIdQuery,
    OwnerByIdQueryVariables
  >(getOwnerById, {
    fetchPolicy: "network-only",
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted(data) {
      setId((data.ownerById && data.ownerById.userId) || "");
      setName((data.ownerById && data.ownerById.name) || "");
      setEmail((data.ownerById && data.ownerById.email) || "");
      setSocialNumber((data.ownerById && data.ownerById.socialNumber) || "");
      setPhone((data.ownerById && data.ownerById.phone) || undefined);
    },
  });

  /* Mutations */

  // Mutation: Update Owner By Id.
  const [updateOwnerMutation] = useMutation<
    UpdateOwnerMutation,
    UpdateOwnerMutationVariables
  >(updateOwner, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Eigandi uppfærður");
    },
    onError(...error) {
      toast.error("Aðgerð heppnaðist ekki: " + error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    props.ownerId &&
      getOwnerByIdQuery({
        variables: {
          id: props.ownerId,
        },
      });
  }, [props.ownerId, getOwnerByIdQuery, props.open]);

  /* Helper functions */

  // isValidToChange decides if we should allow to save details.
  const isValidToChange = () => {
    return (
      name &&
      name !== "" &&
      email &&
      email !== "" &&
      socialNumber &&
      socialNumber !== "" &&
      data &&
      data.ownerById &&
      (data.ownerById.email !== email ||
        data.ownerById.name !== name ||
        data.ownerById.socialNumber !== socialNumber ||
        data.ownerById.phone !== phone)
    );
  };

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>{name}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Einkenni</label>
            <input
              readOnly={true}
              disabled={true}
              value={id}
              placeholder="Nafn"
            />
          </Form.Field>
          <Form.Field>
            <label>Nafn</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nafn"
            />
          </Form.Field>
          <Form.Field>
            <label>Kennitala</label>
            <input
              value={socialNumber}
              onChange={(e) => setSocialNumber(e.target.value)}
              placeholder="Kennitala"
            />
          </Form.Field>
          <Form.Field>
            <label>Póstfang</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Póstfang"
            />
          </Form.Field>
          <Form.Field>
            <label>Símanúmer</label>
            <FieldNoteMessage>
              (Þessi reitur er yfirskrifaður ef íbúi innskráir sig með rafrænum
              skilríkjum)
            </FieldNoteMessage>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Símanúmer"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            // Update the owner
            updateOwnerMutation({
              variables: {
                ownerId: id || "",
                owner: {
                  name: name || "",
                  email: email || "",
                  socialNumber: socialNumber || "",
                  phone: phone || null,
                },
              },
            }).then(() => {
              // Close when succesfully updated.
              props.close();
              // Trigger update event
              props.onUpdated();
            });
          }}
          disabled={!isValidToChange()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Vista breytingar"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default OwnerDetailModal;
