import Cookie from "js-cookie";
import { decode } from "jsonwebtoken";

export const isLoggedIn = () => {
  const token = Cookie.get("token") ? Cookie.get("token") : null;
  if (token != null) {
    try {
      const decodedToken: any = decode(token);
      if (
        decodedToken &&
        decodedToken.exp < (new Date().getTime() + 1) / 1000
      ) {
        console.warn("Expired or malformed token removed", decodedToken);
        logout();
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }
  return false;
};

export const getToken = () => {
  return Cookie.get("token") ? Cookie.get("token") : null;
};

export const getUserIdFromPayload = () => {
  if(!isLoggedIn()) return undefined;

  const token = Cookie.get("token") ? Cookie.get("token") : null;
  const decodedToken: any = decode(token || "");
  return decodedToken.userId || "";
}

export const setToken = (token: string) => {
  Cookie.set("token", token);
};

export const logout = () => {
  Cookie.remove("token");
};
