import * as React from "react";
import {
  Container,
  Header,
  Table,
  Pagination,
  Button,
  Confirm,
  Icon,
  Dropdown,
  Input,
  Popup,
} from "semantic-ui-react";
import is from "date-fns/locale/is";
import { format } from "date-fns";
import Page from "../layout/Page";
import NavHeader from "../layout/Header";
import Loading from "../layout/Loading";
import EmptyStateMessage from "../layout/EmptyState";
import { useMutation, useQuery } from "react-apollo";
import { toast } from "react-toastify";
import {
  DocumentsQuery,
  DocumentsQueryVariables,
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
} from "../../../types";
import { getDocuments, deleteDocument } from "../../queries/documentQueries";
import styled from "styled-components";
import DocumentUploadModal from "../modals/DocumentUploadModal";
import DocumentAssignmentModal from "../modals/DocumentAssignmentModal";
import { getFontAwesomeIconFromMIME } from "../../utils/fileicons";
import useDebounce from "../utils/debounce";
import { DocumentDetailModal } from "../modals/DocumentDetailModal";
import ErrorMessage from "../layout/ErrorMessage";
import {
  getHumanReadableType,
  getKnownDocumentTypesOptions,
} from "../../utils/document";
import { getProperties } from "../../queries/propertyQueries";

interface IDocumentsPageProps {}

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const FilterItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  input {
    border-radius: 4px !important;
  }
`;

const FileLink = styled.a`
  color: #000;
`;

export const DocumentsPage = (props: IDocumentsPageProps) => {
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [deleteConfirm, setDeleteConfirm] = React.useState<number | undefined>(
    undefined
  );
  const [uploadDocumentModalOpen, setUploadDocumentModalOpen] = React.useState<
    boolean
  >();
  const [
    documentAssignmentModalOpen,
    setDocumentAssignmentModalOpen,
  ] = React.useState<boolean>();
  const [selectedDocumentIndex, setSelectedDocumentIndex] = React.useState<
    number
  >();
  const [documentDetailModalOpen, setDocumentDetailModalOpen] = React.useState<
    boolean
  >();
  const [selectedTypeFilters, setSelectedTypeFilters] = React.useState<
    string[]
  >();
  const [selectedPropertyFilters, setSelectedPropertyFilters] = React.useState<
    string[]
  >();
  const [nameFilter, setNameFilter] = React.useState<string>();
  const debouncedNameFilter = useDebounce(nameFilter || "", 500);

  const [deleteDocumentMutation] = useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(deleteDocument, {
    onCompleted() {
      toast.success("Aðgerð heppnaðist: Skjali eytt..");
      refetch();
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Query: Get Properties
  const propertiesDataQuery = useQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    variables: {
      limit: 3000, // TODO: Create a search for this instead.
      page: 0,
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  const { loading, error, data, refetch } = useQuery<
    DocumentsQuery,
    DocumentsQueryVariables
  >(getDocuments, {
    variables: {
      limit: 15,
      page: selectedPage - 1,
      filters: {
        name: debouncedNameFilter,
        type: selectedTypeFilters,
        property: selectedPropertyFilters,
      },
    },
    context: {
      debounceKey: "1",
      debounceTimeout: 1000,
    },
  });

  let documents = (data && data.documents && data.documents.data) || [];
  let documentsCount = data && data.documents ? data.documents.totalCount : 0;
  let selectedDocument =
    (selectedDocumentIndex !== undefined && documents[selectedDocumentIndex]) ||
    undefined;
  let totalPages = Math.ceil((documentsCount || 0) / 15);
  return (
    <>
      <NavHeader />
      <Page>
        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Skjalastjórnun</Header>

          <DocumentUploadModal
            open={uploadDocumentModalOpen || false}
            close={() => setUploadDocumentModalOpen(false)}
            onUpdated={() => refetch()}
          ></DocumentUploadModal>

          <DocumentDetailModal
            open={documentDetailModalOpen || false}
            documentId={(selectedDocument && selectedDocument.id) || ""}
            close={() => setDocumentDetailModalOpen(false)}
            onUpdated={() => {
              refetch();
            }}
          />

          <DocumentAssignmentModal
            open={documentAssignmentModalOpen || false}
            documentId={(selectedDocument && selectedDocument.id) || ""}
            close={() => setDocumentAssignmentModalOpen(false)}
            refetch={() => {
              refetch();
            }}
            loading={loading}
          />

          <FilterWrapper>
            <FilterItem>
              <Dropdown
                placeholder="Leita eftir húsfélögum"
                multiple
                search
                selection
                onChange={(_, { value }) => {
                  let stringArray: string[] = value ? (value as string[]) : [];
                  setSelectedPage(1);
                  setSelectedPropertyFilters(
                    stringArray.length > 0 ? stringArray : undefined
                  );
                }}
                options={propertiesDataQuery?.data?.properties.data.map(
                  (property) => {
                    return {
                      key: property?.id || "",
                      text: property?.address || "",
                      value: property?.id || "",
                    };
                  }
                )}
              />
            </FilterItem>
            <FilterItem>
              <Dropdown
                placeholder="Leita eftir skjalatýpum"
                multiple
                selection
                onChange={(_, { value }) => {
                  let stringArray: string[] = value ? (value as string[]) : [];
                  setSelectedPage(1);
                  setSelectedTypeFilters(
                    stringArray.length > 0 ? stringArray : undefined
                  );
                }}
                options={getKnownDocumentTypesOptions()}
              />
            </FilterItem>
            <FilterItem>
              <Input
                loading={loading}
                icon="address card outline"
                placeholder="Leita eftir nafni"
                onChange={(_, { value }) => {
                  setSelectedPage(1);
                  setNameFilter(value.toString());
                }}
              />
            </FilterItem>
          </FilterWrapper>

          {error ? (
            <ErrorMessage />
          ) : (
            <>
              <Table padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Nafn</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Skjalartýpa</Table.HeaderCell>
                    <Table.HeaderCell>Dagsett</Table.HeaderCell>
                    <Table.HeaderCell># Heimildir</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {loading ? (
                    <Loading />
                  ) : documents ? (
                    documents.map((document, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>
                          <FileLink href={`/file/${document.id}`}>
                            {document && document.name}
                          </FileLink>
                        </Table.Cell>
                        <Table.Cell>
                          {document && (
                            <Popup
                              content={document.mimetype}
                              key={index + "mime"}
                              header={"Skráartýpa"}
                              trigger={
                                <Icon
                                  name={getFontAwesomeIconFromMIME(
                                    document.mimetype
                                  )}
                                />
                              }
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {document && getHumanReadableType(document.type)}
                        </Table.Cell>
                        <Table.Cell>
                          {document &&
                            format(
                              new Date(document.displayDate),
                              "dd. MMMM yyyy",
                              { locale: is }
                            )}
                        </Table.Cell>
                        <Table.Cell>
                          {document && document.properties
                            ? document.properties.length
                            : 0}
                        </Table.Cell>
                        <Table.Cell>
                          <Dropdown text="Aðgerðir">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon="settings"
                                text="Aðgengi húsfélaga"
                                onClick={() => {
                                  setSelectedDocumentIndex(index);
                                  setDocumentAssignmentModalOpen(true);
                                }}
                              />
                              <Dropdown.Item
                                icon="file"
                                text="Breyta skjali"
                                onClick={() => {
                                  setSelectedDocumentIndex(index);
                                  setDocumentDetailModalOpen(true);
                                }}
                              />
                              <Dropdown.Item
                                icon="download"
                                text="Sækja skjal"
                                href={`/file/${document.id}`}
                              />
                              <Dropdown.Divider />
                              <Confirm
                                content="Það er ekki hægt að afturkalla þessa aðgerð. Ertu alveg viss um að þú viljir eyða skjalinu?"
                                open={deleteConfirm === index}
                                cancelButton="Nei"
                                confirmButton="Já"
                                size="tiny"
                                onCancel={() => setDeleteConfirm(undefined)}
                                onConfirm={() =>
                                  deleteDocumentMutation({
                                    variables: {
                                      id: document.id,
                                    },
                                  }).then(() => {
                                    refetch();
                                    setDeleteConfirm(undefined);
                                  })
                                }
                              />
                              <Dropdown.Item
                                icon="delete"
                                text="Eyða skjali"
                                onClick={() => setDeleteConfirm(index)}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <>
                      <EmptyStateMessage
                        message="Bættu við skjölum, eða breytu leitarskilyrðunum."
                        title="Engin skjöl fundust"
                      />
                      <Button
                        icon
                        primary
                        size="small"
                        onClick={() => setUploadDocumentModalOpen(true)}
                      >
                        <Icon name="download" /> Hlaða upp skjölum
                      </Button>
                    </>
                  )}
                </Table.Body>

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="4" textAlign="left">
                      <Pagination
                        defaultActivePage={1}
                        totalPages={totalPages}
                        onPageChange={(e, data) =>
                          setSelectedPage(Number(data.activePage || 1))
                        }
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="3">
                      <Button.Group floated="right">
                        <Button
                          icon
                          primary
                          size="small"
                          onClick={() => setUploadDocumentModalOpen(true)}
                        >
                          <Icon name="user" /> Hlaða upp skjölum
                        </Button>
                        <Button
                          icon
                          floated="right"
                          size="small"
                          onClick={() => refetch()}
                        >
                          <Icon name="refresh" /> Endursækja
                        </Button>
                      </Button.Group>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
              {documentsCount} skjöl fundust <Icon name="file" />
            </>
          )}
        </Container>
      </Page>
    </>
  );
};

export default DocumentsPage;
