import * as React from "react";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import { useMutation, useLazyQuery } from "react-apollo";
import { toast } from "react-toastify";
import {
  getAdminUserById,
  updateAdminUserById,
} from "../../queries/userQueries";
import {
  AdminUserByIdQuery,
  AdminUserByIdQueryVariables,
  AdminRole,
  UpdateAdminUserByIdMutation,
  UpdateAdminUserByIdMutationVariables,
} from "../../../types";
import { toEnumIgnoreCase } from "../../utils/conversion";

interface AdminProfileModalProps {
  open: boolean;
  adminUserId: string;
  close: () => void;
  onUpdated: () => void;
}

/*
 * AdminProfileModal shows detailed information about an admin user,
 * and allows admins to update information about them.
 */
export const AdminProfileModal = (props: AdminProfileModalProps) => {
  // Local state to keep track of changes.
  const [userId, setUserId] = React.useState<string>();
  const [userName, setUserName] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [email, setEmail] = React.useState<string>();
  const [role, setRole] = React.useState<AdminRole | null>();

  /* Queries */

  // Query: Get Admin By Id.
  const [getAdminQuery, { loading, data, refetch }] = useLazyQuery<
    AdminUserByIdQuery,
    AdminUserByIdQueryVariables
  >(getAdminUserById, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    onCompleted(data) {
      if (data) {
        setUserId((data.adminUserById && data.adminUserById.id) || "");
        setUserName((data.adminUserById && data.adminUserById.userName) || "");
        setName((data.adminUserById && data.adminUserById.name) || "");
        setEmail((data.adminUserById && data.adminUserById.email) || "");
        setRole(data.adminUserById && data.adminUserById.role);
      }
    },
  });

  /* Mutations */

  // Mutation: Update Admin By Id.
  const [updateAdminUserByIdMutation] = useMutation<
    UpdateAdminUserByIdMutation,
    UpdateAdminUserByIdMutationVariables
  >(updateAdminUserById, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Notandi uppfærður");
    },
    onError(...error) {
      toast.error("Aðgerð heppnaðist ekki: " + error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    props.adminUserId &&
      getAdminQuery({
        variables: {
          id: props.adminUserId,
        },
      });
  }, [props.adminUserId, getAdminQuery]);

  /* Helper functions */

  // isValidToChange decides if we should allow to save details.
  const isValidToChange = () => {
    return (
      name &&
      name !== "" &&
      email &&
      email !== "" &&
      role &&
      role !== null &&
      data &&
      data.adminUserById &&
      (data.adminUserById.email !== email ||
        data.adminUserById.name !== name ||
        data.adminUserById.userName !== userName ||
        data.adminUserById.role !== role)
    );
  };

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={loading} />
      <Modal.Header>{name}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Einkenni</label>
            <input
              readOnly={true}
              disabled={true}
              value={userId}
              placeholder="Einkenni"
            />
          </Form.Field>
          <Form.Field>
            <label>Nafn</label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nafn"
            />
          </Form.Field>
          <Form.Field>
            <Form.Select
              fluid
              label="Réttindi"
              value={role || ""}
              options={[
                {
                  key: "SUPERUSER",
                  text: "Ofurnotandi",
                  name: "Ofurnotandi",
                  value: "SUPERUSER",
                },
                {
                  key: "UPLOADER",
                  text: "Skjalastjórnandi",
                  name: "Skjalastjórnandi",
                  value: "UPLOADER",
                },
              ]}
              onChange={(_, { value }) => {
                setRole(
                  toEnumIgnoreCase(AdminRole, (value && value.toString()) || "")
                );
              }}
              placeholder="Réttindi"
            />
          </Form.Field>
          <Form.Field>
            <label>Póstfang</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Póstfang"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() => {
            // Update the admin user
            updateAdminUserByIdMutation({
              variables: {
                id: userId || "",
                user: {
                  name: name || "",
                  userName: userName || "",
                  email: email || "",
                  role: role || AdminRole.Superuser,
                },
              },
            }).then(() => {
              refetch();
              // Close when succesfully updated.
              props.close();
              // Trigger update event
              props.onUpdated();
            });
          }}
          disabled={!isValidToChange()}
          positive
          labelPosition="right"
          icon="checkmark"
          content="Vista breytingar"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AdminProfileModal;
