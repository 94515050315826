import * as React from "react";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";

import NavHeader from "../layout/Header";
import Page from "../layout/Page";
import {
  GetIncidentsQuery,
  GetIncidentsQueryVariables,
  DeleteIncidentMutation,
  DeleteIncidentMutationVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
} from "../../../types";
import {
  Table,
  Icon,
  Button,
  Header,
  Container,
  Dropdown,
  Pagination,
  Input,
  Search,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import Loading from "../layout/Loading";
import styled from "styled-components";
import ErrorMessage from "../layout/ErrorMessage";
import useDebounce from "../utils/debounce";
import { deleteIncident, getIncidents } from "../../queries/crmQueries";
import CreateIncidentModal from "../modals/CreateIncidentModal";
import { format, parseISO } from "date-fns";
import { getDateOptions } from "../../utils/date";
import { getProperties } from "../../queries/propertyQueries";
import IncidentDetailsModal from "../modals/IncidentDetailsModal";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const FilterItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  input {
    border-radius: 4px !important;
  }
`;

export const IncidentsPage = () => {
  // Local state to keep track of modals and selected owners
  const [selectedPage, setSelectedPage] = React.useState<number>(1);
  const [createIncidentModalOpen, setCreateIncidentModalOpen] = React.useState<
    boolean
  >();
  const [detailModalOpen, setDetailModalOpen] = React.useState<boolean>();
  const [selectedIncidentIndex, setSelectedIncidentIndex] = React.useState<
    number
  >();
  const [titleFilter, setTitleFilter] = React.useState<string>();
  const debouncedTitleFilter = useDebounce(titleFilter || "", 500);
  const [propertyIdFilter, setPropertyIdFilter] = React.useState<string>();
  const [propertyName, setPropertyName] = React.useState<string>();
  const debouncedPropertyName = useDebounce(propertyName || "", 500);
  const [reporterName, setReporterName] = React.useState<string>();
  const debouncedReporterName = useDebounce(reporterName || "", 500);

  /* Queries */
  const { loading, error, data, refetch } = useQuery<
    GetIncidentsQuery,
    GetIncidentsQueryVariables
  >(getIncidents, {
    variables: {
      limit: 10,
      page: selectedPage,
      filters: {
        title: debouncedTitleFilter,
        propertyId: propertyIdFilter,
        reporterName: debouncedReporterName,
      },
    },
  });

  const [
    searchProperties,
    { loading: searchPropertiesLoading, data: searchPropertiesData },
  ] = useLazyQuery<PropertiesQuery, PropertiesQueryVariables>(getProperties, {
    variables: {
      limit: 10,
      page: 0,
      filters: {
        name: debouncedPropertyName,
      },
    },
  });

  /* Mutations */

  // Mutation: Delete Owners.
  const [deleteIncidentMutation] = useMutation<
    DeleteIncidentMutation,
    DeleteIncidentMutationVariables
  >(deleteIncident, {
    onCompleted() {
      // setCreateIncidentModalOpen(false);
      toast.success("Aðgerð heppnaðist: Atviki eytt.");
    },
    onError(...error) {
      toast.error("Aðgerð misheppnaðist: " + error.map((x) => x.message)[0]);
    },
  });

  // Search owners when changing search
  React.useEffect(() => {
    searchProperties();

    if (propertyName === "") {
      setPropertyIdFilter(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyName]);

  // Search owners when changing search
  React.useEffect(() => {
    setSelectedPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyIdFilter]);

  // Search owners when changing search
  React.useEffect(() => {
    setSelectedPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitleFilter]);

  // Render
  let incidents = (data && data.incidents && data.incidents.data) || [];
  let incidentCount = data && data.incidents ? data.incidents.totalCount : 0;
  let totalPages = Math.ceil((incidentCount || 0) / 15);

  let selectedIncident =
    (selectedIncidentIndex !== undefined && incidents[selectedIncidentIndex]) ||
    undefined;

  if (error) return <h1>ERROR</h1>;

  return (
    <>
      <CreateIncidentModal
        open={createIncidentModalOpen || false}
        close={() => setCreateIncidentModalOpen(false)}
        onUpdated={() => refetch()}
      ></CreateIncidentModal>
      <IncidentDetailsModal
        open={detailModalOpen || false}
        close={() => setDetailModalOpen(false)}
        incidentId={selectedIncident?.id || ""}
      ></IncidentDetailsModal>
      <NavHeader />
      <Page>
        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Atvikaskrá</Header>
          <FilterWrapper>
            <FilterItem>
              <Input
                loading={loading}
                icon="address card outline"
                value={titleFilter}
                placeholder="Leit eftir titil"
                onChange={(_, { value }) => {
                  setTitleFilter(value.toString());
                }}
              />
            </FilterItem>
            <FilterItem>
              <Input
                loading={loading}
                icon="user outline"
                value={reporterName}
                placeholder="Leit eftir tengilið"
                onChange={(_, { value }) => {
                  setReporterName(value.toString());
                }}
              />
            </FilterItem>
            <FilterItem>
              <Search
                icon="home"
                placeholder="Leita eftir húsfélagi"
                loading={searchPropertiesLoading}
                onResultSelect={(e, data) => {
                  setPropertyName(data.result.title || "");
                  setPropertyIdFilter(data.result.value || "");
                }}
                onSearchChange={(p, d) => setPropertyName(d.value || "")}
                results={(searchPropertiesData?.properties?.data || []).map(
                  (property) => {
                    return {
                      description: property.address,
                      title: property.address,
                      value: property.id,
                    };
                  }
                )}
                value={propertyName}
              />
            </FilterItem>
          </FilterWrapper>
          {error ? (
            <ErrorMessage />
          ) : (
            <Table padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Titill</Table.HeaderCell>
                  <Table.HeaderCell>Flokkur</Table.HeaderCell>
                  <Table.HeaderCell>Dagsetning</Table.HeaderCell>
                  <Table.HeaderCell>Tengiliður</Table.HeaderCell>
                  <Table.HeaderCell>Málsnúmer</Table.HeaderCell>
                  <Table.HeaderCell>Húsfélag</Table.HeaderCell>
                  <Table.HeaderCell>Skýring</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {loading ? (
                  <Loading />
                ) : incidents ? (
                  incidents.map((incident, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{incident && incident.title}</Table.Cell>
                      <Table.Cell>{incident && incident.category}</Table.Cell>
                      <Table.Cell>
                        {incident &&
                          format(
                            parseISO(incident.created),
                            "PPP (HH:mm)",
                            getDateOptions("is")
                          )}
                      </Table.Cell>
                      <Table.Cell>
                        {incident && incident.reporterOwner
                          ? incident.reporterOwner.name
                          : incident.reporterOther || "Enginn"}
                      </Table.Cell>
                      <Table.Cell>
                        {(incident && incident.numberReference) || "Ekkert"}
                      </Table.Cell>
                      <Table.Cell>
                        {incident && incident.property.address}
                      </Table.Cell>
                      <Table.Cell>{incident && incident.body}</Table.Cell>
                      <Table.Cell>
                        <Dropdown icon="ellipsis vertical">
                          <Dropdown.Menu>
                            <Dropdown.Item
                              icon="delete"
                              text="Eyða atviki"
                              onClick={() => {
                                deleteIncidentMutation({
                                  variables: {
                                    deleteIncidentId: incident && incident.id,
                                  },
                                }).then(() => refetch());
                              }}
                            />
                            <Dropdown.Item
                              icon="folder open outline"
                              text="Skoða atvik"
                              onClick={() => {
                                setSelectedIncidentIndex(index);
                                setDetailModalOpen(true);
                              }}
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>Ekkert atvik á skrá</Table.Row>
                )}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="3" textAlign="left">
                    <Pagination
                      defaultActivePage={1}
                      totalPages={totalPages}
                      onPageChange={(e, data) => {
                        const nextPage = data.activePage;
                        setSelectedPage(
                          nextPage ? parseInt(nextPage.valueOf().toString()) : 1
                        );
                      }}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="5">
                    <Button.Group floated="right">
                      <Button
                        icon
                        primary
                        size="small"
                        onClick={() => {
                          setCreateIncidentModalOpen(true);
                        }}
                      >
                        <Icon name="add square" /> Búa til atvik
                      </Button>
                    </Button.Group>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </Container>
      </Page>
    </>
  );
};

export default IncidentsPage;
