import * as React from "react";
import {
  Modal,
  Loader,
  Button,
  List,
  Dropdown,
  Header,
  Container,
  Divider,
} from "semantic-ui-react";
import {
  AssignOwnerToPropertyMutation,
  AssignOwnerToPropertyMutationVariables,
  UnassignOwnerFromPropertyMutation,
  UnassignOwnerFromPropertyMutationVariables,
  PropertiesQuery,
  PropertiesQueryVariables,
} from "../../../types";
import { useMutation, useLazyQuery } from "react-apollo";
import {
  assignOwnerToProperty,
  unassignOwnerFromProperty,
  getProperties,
} from "../../queries/propertyQueries";
import { notEmpty } from "../../utils/array";
import { toast } from "react-toastify";
import styled from "styled-components";

interface AssignToPropertyModalProps {
  open: boolean;
  ownerId?: string;
  loading: boolean;
  properties: { id: string; address: string }[];
  close: () => void;
  refetch: () => void;
}

const NoResultsContainer = styled.div`
  margin: 30px;
`;

const ListRowWrapper = styled.div`
  display: flex;
  margin: 10px;
`;

const LeftSideRow = styled.div`
  margin-right: auto;
`;

const RightSideRow = styled.div`
  margin-left: auto;
`;

export const AssignToPropertyModal = (props: AssignToPropertyModalProps) => {
  // Local state to track assignments
  const [selectedPropertyId, setselectedProperty] = React.useState<
    string | undefined
  >();

  /* Mutations */

  // Mutation: Assign owner to property
  const [assignsOwnerToPropertyMutation] = useMutation<
    AssignOwnerToPropertyMutation,
    AssignOwnerToPropertyMutationVariables
  >(assignOwnerToProperty, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Íbúa gefið leyfi fyrir húsfélagi");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Unassign owner to property
  const [unassignOwnerFromPropertyMutation] = useMutation<
    UnassignOwnerFromPropertyMutation,
    UnassignOwnerFromPropertyMutationVariables
  >(unassignOwnerFromProperty, {
    onCompleted(data) {
      toast.success("Aðgerð heppnaðist: Leyfi tekið af íbúa");
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  /* Queries */

  // Query: Get Properties
  const [getPropertiesQuery, { loading, data }] = useLazyQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    variables: {
      limit: 1000,
      page: 0,
    },
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
  });

  // Effects
  React.useEffect(() => {
    props.ownerId && getPropertiesQuery();
  }, [props.ownerId, getPropertiesQuery]);

  // Render
  return (
    <Modal open={props.open}>
      <Loader active={props.loading && loading} />
      <Modal.Header>Aðgangur að húsfélögum</Modal.Header>
      <Modal.Content>
        {props.properties.length > 0 ? (
          <List divided verticalAlign="middle">
            {props.properties.map((property, index) => (
              <List.Item key={index}>
                <List.Icon
                  name="building"
                  size="large"
                  verticalAlign="middle"
                />
                <List.Content>
                  <ListRowWrapper>
                    <LeftSideRow>
                      <List.Header as="h3">{property.address}</List.Header>
                      <List.Description as="p">{property.id}</List.Description>
                    </LeftSideRow>
                    <RightSideRow>
                      <Button
                        color="red"
                        onClick={() => {
                          unassignOwnerFromPropertyMutation({
                            variables: {
                              propertyId: property.id,
                              ownerId: props.ownerId || "",
                            },
                          }).then(() => props.refetch());
                        }}
                      >
                        Eyða aðgengi
                      </Button>
                    </RightSideRow>
                  </ListRowWrapper>
                </List.Content>
              </List.Item>
            ))}
          </List>
        ) : (
          <Container textAlign="center">
            <NoResultsContainer>
              <Header>Ekki aðgang að neinu húsfélagi.</Header>
            </NoResultsContainer>
          </Container>
        )}
        <Divider />

        <Button as="div" labelPosition="left">
          <Dropdown
            placeholder="Veldu húsfélag til að gefa aðgang að"
            options={
              (data &&
                data.properties &&
                data.properties.data
                  .filter(notEmpty)
                  .filter(
                    (f) => !props.properties.map((p) => p.id).includes(f.id)
                  )
                  .map((property, index) => {
                    return {
                      key: property.id,
                      text: property.address,
                      value: property.id,
                    };
                  })) ||
              []
            }
            selection
            search
            onChange={(_, { value }) => {
              setselectedProperty((value && value.toString()) || "");
            }}
            className="label"
          />
          <Button
            onClick={() =>
              selectedPropertyId &&
              assignsOwnerToPropertyMutation({
                variables: {
                  propertyId: selectedPropertyId,
                  ownerId: props.ownerId || "",
                },
              }).then(() => props.refetch())
            }
            labelPosition="left"
            primary
            icon="add"
            content="Gefa aðgang að húsfélagi"
          />
        </Button>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => props.close()}
          labelPosition="right"
          icon="checkmark"
          content="Loka"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AssignToPropertyModal;
