import React from "react";
import styled from "styled-components";
import { Message, SemanticCOLORS } from "semantic-ui-react";

interface EmptyStateMessageProps {
  message: string;
  title: string;
  color?: SemanticCOLORS;
}

const LoadingWrapper = styled.div`
  margin: 30px;
`;

export const EmptyStateMessage = (props: EmptyStateMessageProps) => {
  return (
    <LoadingWrapper>
      <Message color={props.color}>
        <Message.Header>{props.title}</Message.Header>
        <p>{props.message}</p>
      </Message>
    </LoadingWrapper>
  );
};

export default EmptyStateMessage;
