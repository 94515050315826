import React from "react";
import styled from "styled-components";
import { Message } from "semantic-ui-react";

const LoadingWrapper = styled.div`
  height: 650px;
  margin: 30px;
`;

export const ErrorMessage = () => {
  return (
    <LoadingWrapper>
      <Message>
        <Message.Header>Villa</Message.Header>
        <p>Það er eitthvað að, prófaðu aftur seinna.</p>
      </Message>
    </LoadingWrapper>
  );
};

export default ErrorMessage;
