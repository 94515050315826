import * as React from "react";
import { useMutation, useQuery } from "react-apollo";

import {
  getProperties,
  createProperty,
  deleteProperty,
} from "../../queries/propertyQueries";
import NavHeader from "../layout/Header";
import Page from "../layout/Page";
import {
  PropertiesQuery,
  CreatePropertyMutation,
  CreatePropertyMutationVariables,
  PropertyInput,
  DeletePropertyMutation,
  DeletePropertyMutationVariables,
  PropertiesQueryVariables,
} from "../../../types";
import {
  Button,
  Table,
  Icon,
  Header,
  Container,
  Dropdown,
  Confirm,
  Pagination,
  Input,
} from "semantic-ui-react";
import PropertyCreateModal from "../modals/PropertyCreateModal";
import { toast } from "react-toastify";
import Loading from "../layout/Loading";
import { OwnersInPropertyModal } from "../modals/OwnersInPropertyModal";
import ErrorMessage from "../layout/ErrorMessage";
import PropertyContactManageModal from "../modals/PropertyContactManageModal";
import styled from "styled-components";
import useDebounce from "../utils/debounce";
import OwnersInPropertyExportModal from "../modals/OwnersInPropertyExportModal";
import { PropertyInformationCardModal } from "../modals/PropertyInformationCardModal";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

const FilterItem = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  input {
    border-radius: 4px !important;
  }
`;

export const PropertiesPage = () => {
  const [selectedPage, setSelectedPage] = React.useState<number>(1);

  // Local state to keep track of modals
  const [ownerExportModalOpen, setOwnerExportModalOpen] = React.useState<
    boolean
  >();
  const [createModalOpen, setCreateModalOpen] = React.useState<boolean>();
  const [
    managePropertyInformationModal,
    setManagePropertyInformationModal,
  ] = React.useState<boolean>();
  const [manageContactsModalOpen, setManageContactsModalOpen] = React.useState<
    boolean
  >();
  const [deleteConfirm, setDeleteConfirm] = React.useState<number | undefined>(
    undefined
  );
  const [selectedPropertyIndex, setSelectedPropertyIndex] = React.useState<
    number
  >();
  const [
    propertyOwnersDetailModalOpen,
    setPropertyOwnersDetailModalOpen,
  ] = React.useState<boolean>();
  const [nameFilter, setNameFilter] = React.useState<string>();
  const debouncedNameFilter = useDebounce(nameFilter || "", 500);

  // Query: Get Properties
  const { loading, error, data, refetch } = useQuery<
    PropertiesQuery,
    PropertiesQueryVariables
  >(getProperties, {
    onError(...error) {
      toast.error(error.map((x) => x.message)[0]);
    },
    variables: {
      limit: 15,
      page: selectedPage - 1,
      filters: {
        name: debouncedNameFilter,
      },
    },
    context: {
      debounceKey: "3",
      debounceTimeout: 1000,
    },
  });

  // Mutation: Create Property
  const [createPropertyMutation, createPropertyData] = useMutation<
    CreatePropertyMutation,
    CreatePropertyMutationVariables
  >(createProperty, {
    onCompleted() {
      setCreateModalOpen(false);
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  // Mutation: Delete Property
  const [deletePropertyMutation] = useMutation<
    DeletePropertyMutation,
    DeletePropertyMutationVariables
  >(deleteProperty, {
    onCompleted() {
      setCreateModalOpen(false);
    },
    onError(...error) {
      toast(error.map((x) => x.message)[0]);
    },
  });

  let properties = data?.properties.data || [];
  let propertiesCount = data && data.properties.totalCount;
  let selectedProperty =
    (selectedPropertyIndex !== undefined &&
      properties[selectedPropertyIndex]) ||
    undefined;
  let totalPages = Math.ceil((propertiesCount || 0) / 15);

  return (
    <>
      <NavHeader />
      <Page>
        <PropertyCreateModal
          open={createModalOpen || false}
          close={() => setCreateModalOpen(false)}
          loading={createPropertyData.loading}
          createProperty={(property: PropertyInput) => {
            createPropertyMutation({
              variables: {
                property: property,
              },
            }).then(() => refetch());
          }}
        />

        {selectedProperty && (
          <>
            {managePropertyInformationModal && (
              <PropertyInformationCardModal
                key={"propertyinformationcard"}
                open={managePropertyInformationModal || false}
                close={() => {
                  setManagePropertyInformationModal(false);
                  setSelectedPropertyIndex(undefined);
                }}
                onChanged={() => refetch()}
                propertyId={selectedProperty.id}
                propertyName={selectedProperty.address}
              />
            )}
            {propertyOwnersDetailModalOpen && (
              <OwnersInPropertyModal
                key="ownerinproperty"
                open={propertyOwnersDetailModalOpen || false}
                close={() => {
                  setPropertyOwnersDetailModalOpen(false);
                  setSelectedPropertyIndex(undefined);
                }}
                propertyId={selectedProperty.id}
                title={selectedProperty.address}
                onUpdate={() => refetch()}
              />
            )}
            {ownerExportModalOpen && (
              <OwnersInPropertyExportModal
                key="ownersinpropertyexport"
                open={ownerExportModalOpen || false}
                close={() => {
                  setOwnerExportModalOpen(false);
                  setSelectedPropertyIndex(undefined);
                }}
                propertyId={selectedProperty.id}
                title={selectedProperty.address}
              />
            )}
            {manageContactsModalOpen && (
              <PropertyContactManageModal
                key="propertycontantsmanage"
                open={manageContactsModalOpen || false}
                close={() => {
                  setManageContactsModalOpen(false);
                  setSelectedPropertyIndex(undefined);
                }}
                propertyId={selectedProperty.id}
                onChanged={() => refetch()}
                propertyName={selectedProperty.address}
              />
            )}
          </>
        )}

        <Container style={{ maxWidth: 800 }}>
          <Header as="h2">Húsfélög á skrá</Header>
          <FilterWrapper>
            <FilterItem>
              <Input
                loading={loading}
                icon="text cursor"
                placeholder="Leita eftir nafni"
                onChange={(_, { value }) => {
                  setSelectedPage(1);
                  setNameFilter(value.toString());
                }}
              />
            </FilterItem>
          </FilterWrapper>

          {error ? (
            <ErrorMessage />
          ) : (
            <>
              <Table padded>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Heimilisfang / Nafn</Table.HeaderCell>
                    <Table.HeaderCell>NAV: Einkenni</Table.HeaderCell>
                    <Table.HeaderCell>NAV: Kennitala</Table.HeaderCell>
                    <Table.HeaderCell># Íbúar</Table.HeaderCell>
                    <Table.HeaderCell># Upplýsingaspjald</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                {loading ? (
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <Loading />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                ) : data?.properties ? (
                  <Table.Body>
                    {data?.properties.data.map((property, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{property?.address}</Table.Cell>
                        <Table.Cell>{property?.externalId}</Table.Cell>
                        <Table.Cell>{property?.registrationId}</Table.Cell>
                        <Table.Cell>{property?.owners?.length || 0}</Table.Cell>
                        <Table.Cell>
                          {property?.information?.length || 0}
                        </Table.Cell>
                        <Table.Cell>
                          <Confirm
                            open={deleteConfirm === index}
                            onCancel={() => setDeleteConfirm(undefined)}
                            cancelButton="Nei"
                            confirmButton="Já"
                            content="Ertu viss?"
                            size="tiny"
                            onConfirm={() =>
                              deletePropertyMutation({
                                variables: {
                                  id: property?.id || "",
                                },
                              }).then(() => {
                                refetch();
                                setDeleteConfirm(undefined);
                              })
                            }
                          />
                          <Dropdown text="Aðgerðir">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                icon="delete"
                                text="Eyða húsfélagi"
                                onClick={() => setDeleteConfirm(index)}
                              />
                              <Dropdown.Item
                                icon="users"
                                text="Skoða íbúa"
                                onClick={() => {
                                  setSelectedPropertyIndex(index);
                                  setPropertyOwnersDetailModalOpen(true);
                                }}
                              />
                              <Dropdown.Item
                                icon="address card outline"
                                text="Tengiliðir húsfélags"
                                onClick={() => {
                                  setSelectedPropertyIndex(index);
                                  setManageContactsModalOpen(true);
                                }}
                              />
                              <Dropdown.Item
                                icon="info circle"
                                text="Upplýsingaspjald húsfélags"
                                onClick={() => {
                                  setSelectedPropertyIndex(index);
                                  setManagePropertyInformationModal(true);
                                }}
                              />
                              <Dropdown.Item
                                icon="file excel outline"
                                text="Sækja gögn um íbúa (.xlsx)"
                                onClick={() => {
                                  setSelectedPropertyIndex(index);
                                  setOwnerExportModalOpen(true);
                                }}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                ) : (
                  <Table.Body>
                    <Table.Row>Enginn húsfélög fundust</Table.Row>
                  </Table.Body>
                )}

                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="3" textAlign="left">
                      <Pagination
                        defaultActivePage={1}
                        totalPages={totalPages}
                        onPageChange={(e, data) =>
                          setSelectedPage(Number(data.activePage || 1))
                        }
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan="3">
                      <Button.Group floated="right">
                        <Button
                          icon
                          labelPosition="left"
                          primary
                          size="small"
                          onClick={() => setCreateModalOpen(true)}
                        >
                          <Icon name="user" /> Búa til húsfélag
                        </Button>
                        <Button
                          icon
                          floated="right"
                          size="small"
                          onClick={() => refetch()}
                        >
                          <Icon name="refresh" /> Endursækja
                        </Button>
                      </Button.Group>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </>
          )}
        </Container>
      </Page>
    </>
  );
};

export default PropertiesPage;
