import { is, enUS } from "date-fns/esm/locale";

export const getDateOptions = (locale: "is" | "en") => {
  if (locale === "is") {
    return {
      locale: is,
    };
  }

  return {
    locale: enUS,
  };
};
