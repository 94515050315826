import { gql } from "apollo-boost";

export const getPropertContacts = gql`
  query PropertyContacts($role: String!) {
    propertyContacts(role: $role) {
      id
      name
      role
      phone
      email
      created
      modified
      propertyId
      propertyName
    }
  }
`;
