import * as React from "react";
import { Modal, Loader, Button, Form } from "semantic-ui-react";
import { PropertyInput } from "../../../types";

interface PropertyCreateModalProps {
  open: boolean;
  loading: boolean;
  close: () => void;
  createProperty: (property: PropertyInput) => void;
}

export const PropertyCreateModal = (props: PropertyCreateModalProps) => {
  const [address, setAddress] = React.useState<string>();
  const [externalId, setExternalId] = React.useState<string>();
  const [registrationId, setRegistrationId] = React.useState<string>();

  const isValid = () => {
    return address && address !== "";
  };

  return (
    <Modal open={props.open}>
      <Loader active={props.loading} />
      <Modal.Header>Búa til húsfélag</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Heimilisfang</label>
            <input
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Heimilisfang"
            />
          </Form.Field>
          <Form.Field>
            <label>NAV: Einkenni</label>
            <input
              onChange={(e) => setExternalId(e.target.value)}
              placeholder="Einkenni"
            />
          </Form.Field>
          <Form.Field>
            <label>NAV: Kennitala</label>
            <input
              onChange={(e) => setRegistrationId(e.target.value)}
              placeholder="Kennitala"
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => props.close()} negative>
          Hætta við
        </Button>
        <Button
          onClick={() =>
            props.createProperty({
              address: address || "",
              externalId: externalId,
              registrationId: registrationId,
            })
          }
          positive
          disabled={!isValid()}
          labelPosition="right"
          icon="checkmark"
          content="Búa til húsfélag"
        />
      </Modal.Actions>
    </Modal>
  );
};

export default PropertyCreateModal;
